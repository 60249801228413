package de.kampfkalender.web.components

import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.formatAsDate
import de.kampfkalender.web.utils.formatAsTime
import de.kampfkalender.web.utils.getLogoWide
import js.core.jso
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogScroll
import mui.material.DialogTitle
import mui.material.Divider
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.responsive
import org.w3c.dom.url.URL
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.useContext
import web.cssom.AlignItems
import web.cssom.AspectRatio
import web.cssom.FlexWrap
import web.cssom.JustifyContent
import web.cssom.em
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.rem
import web.cssom.vh
import web.window.WindowTarget

external interface EventDialogProps : Props {
    var open: Boolean?
    var event: ExportedEvent?
    var onClose: () -> Unit
}

val EventDialog = FC<EventDialogProps> { props ->
    if (props.event == null) {
        return@FC
    }

    val app = useContext(ApplicationContext)!!
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val event = props.event!!
    val date = event.time.formatAsDate()
    val timeRange = event.time.formatAsTime() + " - " + event.until.formatAsTime() + " Uhr"

    val closeDialog: () -> Unit = {
        props.onClose()
    }

    Dialog {
        open = props.open == true
        keepMounted = false
        maxWidth = "md"
        fullWidth = true
        scroll = DialogScroll.paper
        onClose = { _, _ -> closeDialog() }

        DialogTitle {
            @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
            this as react.PropsWithChildren

            // title & chips left, date & time right
            Stack {
                sx = jso {
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.start
                }

                if (isSmall) {
                    direction = responsive(StackDirection.columnReverse)
                } else {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(0.5.rem)
                }

                // title & chips
                Box {
                    Typography {
                        component = ReactHTML.h2
                        variant = TypographyVariant.h3

                        +event.title
                    }

                    Box {
                        sx = jso {
                            if (!isSmall) {
                                marginTop = 0.5.rem
                            }
                        }

                        EventTags {
                            this.event = event
                            withBroadcaster = false
                            fromDialog = true
                        }
                    }
                }

                if (isSmall) {
                    Divider()
                }

                // date & time
                Stack {
                    sx = jso {
                        flexShrink = number(0.0)
                        if (isSmall) {
                            justifyContent = JustifyContent.spaceBetween
                            alignItems = AlignItems.start
                            width = 100.pct
                        } else {
                            alignItems = AlignItems.end
                        }
                    }

                    if (isSmall) {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(0.5.rem)
                    } else {
                        direction = responsive(StackDirection.column)
                    }

                    Typography {
                        sx = jso {
                            if (isSmall) {
                                fontSize = 0.9.rem
                            }
                        }

                        +date
                    }
                    Typography {
                        sx = jso {
                            if (isSmall) {
                                fontSize = 0.9.rem
                            }
                        }

                        +timeRange
                    }
                }
            }
        }

        DialogContent {
            dividers = true

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(1.em)

                Box {
                    val youtube = event.broadcasters.firstNotNullOfOrNull {
                        if (it.value.url?.startsWith("https://www.youtube.com/watch?v", true) == true) {
                            it
                        } else {
                            null
                        }
                    }

                    val youtubeUrl = if (youtube != null) {
                        try {
                            URL(youtube.value.url!!)
                        } catch (_: Throwable) {
                            null
                        }
                    } else {
                        null
                    }

                    val youtubeEmbedded = youtubeUrl != null && youtubeUrl.searchParams.has("v")
                    if (youtubeUrl != null && youtubeEmbedded) {
                        val videoId = youtubeUrl.searchParams.get("v")!!

                        iframe {
                            style = jso {
                                @Suppress("CAST_NEVER_SUCCEEDS", "UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
                                aspectRatio = "16 / 9" as? AspectRatio
                                width = 100.pct
                            }

                            src = "https://www.youtube-nocookie.com/embed/$videoId"
                            //frameBorder = 0
                            allow =
                                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen = true
                        }
                    }

                    val broadcasters = event.broadcasters.filter {
                        !youtubeEmbedded || it.key != youtube?.key
                    }

                    if (broadcasters.isNotEmpty()) {
                        Typography {
                            sx = jso {
                                marginBottom = 10.px
                            }

                            component = ReactHTML.h3
                            variant = TypographyVariant.h4

                            if (youtubeEmbedded) {
                                +"Oder anschauen bei"
                            } else {
                                +"Anschauen bei"
                            }
                        }

                        Stack {
                            sx = jso {
                                flexWrap = FlexWrap.wrap
                                alignItems = AlignItems.center
                                marginTop = (-0.4).rem
                                marginLeft = (-0.7).rem
                            }

                            direction = responsive(StackDirection.row)
                            //spacing = responsive(1.rem)

                            broadcasters.forEach {
                                val broadcaster = it.key
                                val broadcastInfo = it.value
                                key = broadcaster.name

                                val logo = broadcaster.getLogoWide(app.darkMode)
                                if (logo == null) {
                                    div {
                                        +broadcaster.title
                                    }
                                    return@forEach
                                }

                                Box {
                                    sx = jso {
                                        marginTop = 0.4.rem
                                        marginLeft = 0.7.rem
                                    }

                                    Tooltip {
                                        title = ReactNode(broadcaster.title)
                                        Link {
                                            href = broadcastInfo.url
                                                ?: broadcaster.streamUrl
                                                        ?: event.promotion.streamUrl
                                                        ?: broadcaster.url
                                                        ?: "#"
                                            target = WindowTarget._blank

                                            img {
                                                alt = broadcaster.title
                                                src = "/logos/$logo"
                                                style = jso {
                                                    maxWidth = 100.pct
                                                    maxHeight = 30.px
                                                    height = 100.vh
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (!event.promotion.podcast && !event.promo && event.card.isNotEmpty()) {
                    Box {
                        Typography {
                            component = ReactHTML.h3
                            variant = TypographyVariant.h4

                            +"Fight Card"
                        }
                        Typography {
                            sx = jso {
                                marginTop = 0.5.rem
                                paddingLeft = 1.rem
                            }
                            component = ReactHTML.ul

                            event.card.forEach {
                                li {
                                    +it
                                }
                            }
                        }
                    }
                }

                if (event.links.isNotEmpty()) {
                    Box {
                        Typography {
                            component = ReactHTML.h3
                            variant = TypographyVariant.h4

                            +"Weitere Links"
                        }

                        Typography {
                            sx = jso {
                                marginTop = 0.5.rem
                                paddingLeft = 1.rem
                            }
                            component = ReactHTML.ul

                            event.links.forEach {
                                val url = try {
                                    URL(it)
                                } catch (_: Throwable) {
                                    return@forEach
                                }

                                li {
                                    Link {
                                        href = it
                                        target = WindowTarget._blank
                                        underline = LinkUnderline.hover
                                        +url.hostname
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        DialogActions {
            Button {
                onClick = {
                    closeDialog()
                }

                +"Schließen"
            }
        }
    }
}
