package de.kampfkalender.web.components

import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.common.data.BroadcastInfo
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.getLogo
import de.kampfkalender.web.utils.getLogoScale
import de.kampfkalender.web.utils.getLogoWidth
import de.kampfkalender.web.utils.roundTo
import js.core.jso
import mui.material.Box
import mui.material.Chip
import mui.material.ChipColor
import mui.material.Size
import mui.material.useMediaQuery
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import react.useContext
import web.cssom.AlignContent
import web.cssom.Auto
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FlexWrap
import web.cssom.JustifyContent
import web.cssom.VerticalAlign
import web.cssom.WhiteSpace
import web.cssom.integer
import web.cssom.number
import web.cssom.px
import web.cssom.rem

external interface EventTagsProps : Props {
    var event: ExportedEvent
    var withBroadcaster: Boolean?
    var fromDialog: Boolean?
}

val EventTags = FC<EventTagsProps> { props ->
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val isFromDialog = props.fromDialog == true

    Box {
        sx = jso {
            display = Display.flex
            flexDirection = FlexDirection.row
            marginTop = (-0.4).rem
            marginBottom = 0.rem
            marginRight = 0.rem

            if (isSmall) {
                marginLeft = (-0.4).rem
                flexWrap = FlexWrap.wrap
                justifyContent = if (isFromDialog) JustifyContent.start else JustifyContent.center
            }
        }

        if (isSmall) {

            if (props.withBroadcaster == true) {
                props.event.broadcasters.forEach {
                    BroadcastTag {
                        broadcaster = it.key
                        info = it.value
                    }
                }
            }

            //if (props.event.promo) {
            //    EventTag {
            //        key = "is-promo"
            //        title = "PR"
            //        color = ChipColor.primary
            //    }
            //}

            if (props.event.promotion.podcast) {
                EventTag {
                    key = "is-podcast"
                    title = "Podcast"
                    color = ChipColor.primary
                }
            }

            props.event.sports.forEach {
                EventTag {
                    key = it.name
                    title = it.title
                    color = ChipColor.primary
                }
            }

            if (props.event.live) {
                EventTag {
                    key = "is-live"
                    title = "live"
                    color = ChipColor.secondary
                }
            }

            if (props.event.isFree()) {
                EventTag {
                    key = "is-free"
                    title = "kostenlos"
                    color = ChipColor.success
                }
            }

        } else {
            if (props.withBroadcaster == true) {
                Box {
                    sx = jso {
                        flexGrow = number(0.0)

                        display = Display.flex
                        flexDirection = FlexDirection.row
                        flexWrap = FlexWrap.wrap
                        order = integer(1)
                        flexShrink = number(0.5)
                        justifyContent = JustifyContent.flexEnd
                        alignContent = AlignContent.flexStart
                        marginLeft = Auto.auto
                    }

                    props.event.broadcasters.forEach {
                        BroadcastTag {
                            broadcaster = it.key
                            info = it.value
                        }
                    }
                }
            }

            Box {
                sx = jso {
                    marginLeft = (-0.3).rem
                    order = integer(0)
                    flexGrow = number(0.0)
                    flexShrink = number(1.0)

                    display = Display.flex
                    flexDirection = FlexDirection.row
                    flexWrap = FlexWrap.wrap
                    justifyContent = JustifyContent.flexStart
                }

                //if (props.event.promo) {
                //    EventTag {
                //        key = "is-promo"
                //        title = "PR"
                //        color = ChipColor.primary
                //    }
                //}

                if (props.event.promotion.podcast) {
                    EventTag {
                        key = "is-podcast"
                        title = "Podcast"
                        color = ChipColor.primary
                    }
                }

                props.event.sports.forEach {
                    EventTag {
                        key = it.name
                        title = it.title
                        color = ChipColor.primary
                    }
                }

                if (props.event.live) {
                    EventTag {
                        key = "is-live"
                        title = "live"
                        color = ChipColor.secondary
                    }
                }

                if (props.event.isFree()) {
                    EventTag {
                        key = "is-free"
                        title = "kostenlos"
                        color = ChipColor.success
                    }
                }
            }
        }
    }
}

private external interface EventTagProps : Props {
    var title: String
    var color: ChipColor
}

private val EventTag = FC<EventTagProps> { props ->
    Box {
        sx = jso {
            flexGrow = number(0.0)
            flexShrink = number(0.0)
            marginTop = 0.4.rem
            marginLeft = 0.3.rem
        }

        Chip {
            sx = jso {
                whiteSpace = WhiteSpace.nowrap
            }

            color = props.color
            label = ReactNode(props.title)
            size = Size.small
        }
    }
}

private external interface BroadcastTagProps : Props {
    var broadcaster: Broadcaster
    var info: BroadcastInfo
}

private val BroadcastTag = FC<BroadcastTagProps> { props ->
    val app = useContext(ApplicationContext)!!

    Box {
        sx = jso {
            flexGrow = number(0.0)
            flexShrink = number(0.0)
            marginTop = 0.4.rem
            marginLeft = 0.4.rem
            whiteSpace = WhiteSpace.nowrap
        }

        val logo = props.broadcaster.getLogo(app.darkMode)
        if (logo == null) {
            +props.broadcaster.title
        } else {
            ReactHTML.img {
                alt = props.broadcaster.title
                src = "/logos/$logo"
                style = jso {
                    val h = (20 * props.broadcaster.getLogoScale())
                    height = h.px
                    width = props.broadcaster.getLogoWidth(h).roundTo(2).px
                    verticalAlign = VerticalAlign.middle
                }
            }
        }
    }
}
