package de.kampfkalender.web.utils

import de.kampfkalender.common.Broadcaster

internal fun Broadcaster.getLogo(darkMode: Boolean = false): String? {
    return when (this) {
        Broadcaster.ARD -> "ard.svg" // if (darkMode) "ard.dark.svg" else "ard.svg"
        Broadcaster.BILD_PLUS -> "bild-plus.svg" // if (darkMode) "bild-plus.dark.svg" else "bild-plus.svg"
        Broadcaster.BILD_TV -> "bild.svg" // if (darkMode) "bild.dark.svg" else "bild.svg"
        Broadcaster.BRAVECF_TV -> "bravecftv.png" // if (darkMode) "bravecftv.dark.png" else "bravecftv.png"
        Broadcaster.DAZN -> if (darkMode) "dazn.dark.svg" else "dazn.svg"
        Broadcaster.EMC_TV -> "emc.png" // if (darkMode) "emc.dark.png" else "emc.png"
        Broadcaster.EUROSPORT -> if (darkMode) "eurosport.dark.svg" else "eurosport.svg"
        Broadcaster.EUROSPORT2 -> if (darkMode) "eurosport2.dark.svg" else "eurosport2.svg"
        Broadcaster.FACEBOOK -> "facebook.svg" // if (darkMode) "facebook.dark.svg" else "facebook.svg"
        Broadcaster.FIGHT24_TV -> if (darkMode) "fight24.dark.svg" else "fight24.svg"
        Broadcaster.FIGHTING_DE -> if (darkMode) "fighting.dark.png" else "fighting.png"
        Broadcaster.FITE_TV -> "fite.svg" // if (darkMode) "fite.dark.svg" else "fite.svg"
        Broadcaster.FLX_CAST -> if (darkMode) "flxcast.dark.png" else "flxcast.png"
        Broadcaster.GLORY_TV -> if (darkMode) "glory.dark.svg" else "glory.svg"
        Broadcaster.GMC_TV -> if (darkMode) "gmc.dark.svg" else "gmc.svg"
        Broadcaster.IMMAF_TV -> if (darkMode) "immaf.dark.svg" else "immaf.svg"
        Broadcaster.KSW_TV -> "ksw.png" // if (darkMode) "ksw.dark.png" else "ksw.png"
        Broadcaster.MDR -> "mdr.svg" // if (darkMode) "mdr.dark.svg" else "mdr.svg"
        Broadcaster.MEINSPORTPODCAST_DE -> if (darkMode) "meinsportpodcast.dark.png" else "meinsportpodcast.png"
        Broadcaster.OKTAGON_TV -> if (darkMode) "oktagon.dark.svg" else "oktagon.svg"
        Broadcaster.ONE_TV -> if (darkMode) "one.dark.svg" else "one.svg"
        Broadcaster.PLUTO_TV -> if (darkMode) "plutotv.dark.svg" else "plutotv.svg"
        Broadcaster.PRO7_MAXX -> if (darkMode) "prosieben-maxx.dark.svg" else "prosieben-maxx.svg"
        Broadcaster.RAN_FIGHTING -> if (darkMode) "ranfighting.dark.png" else "ranfighting.png"
        Broadcaster.SEGI_TV -> if (darkMode) "segitv.dark.png" else "segitv.png"
        Broadcaster.SPORT1 -> if (darkMode) "sport1.dark.svg" else "sport1.svg"
        Broadcaster.SPORT1_EXTRA -> if (darkMode) "sport1-extra.dark.png" else "sport1-extra.png"
        Broadcaster.SPORTSCHAU_DE -> if (darkMode) "sportschau.dark.png" else "sportschau.png"
        Broadcaster.UFC_FIGHT_PASS -> if (darkMode) "fightpass.dark.svg" else "fightpass.svg"
        Broadcaster.YOUTUBE -> if (darkMode) "youtube.dark.svg" else "youtube.svg"
        Broadcaster.XYZSPORTS_TV -> if (darkMode) "xyzsports.dark.svg" else "xyzsports.svg"
        Broadcaster.ZDF -> "zdf.svg" // if (darkMode) "zdf.dark.svg" else "zdf.svg"
        else -> null
    }
}

internal fun Broadcaster.getLogoWide(darkMode: Boolean = false): String? {
    return when (this) {
        Broadcaster.EMC_TV -> if (darkMode) "emc.wide.dark.png" else "emc.wide.png"
        Broadcaster.FIGHT24_TV -> if (darkMode) "fight24.wide.dark.svg" else "fight24.wide.svg"
        Broadcaster.FIGHTING_DE -> if (darkMode) "fighting.wide.dark.svg" else "fighting.wide.svg"
        Broadcaster.UFC_FIGHT_PASS -> if (darkMode) "fightpass.wide.dark.svg" else "fightpass.wide.svg"
        Broadcaster.YOUTUBE -> if (darkMode) "youtube.wide.dark.svg" else "youtube.wide.svg"
        else -> this.getLogo(darkMode = darkMode)
    }
}

internal fun Broadcaster.getLogoScale(): Double {
    return when (this) {
        Broadcaster.BILD_PLUS -> 1.1
        Broadcaster.BILD_TV -> 1.1
        Broadcaster.DAZN -> 1.1
        Broadcaster.EMC_TV -> 0.9
        Broadcaster.EUROSPORT -> 0.8
        Broadcaster.EUROSPORT2 -> 0.8
        Broadcaster.FIGHT24_TV -> 0.9
        Broadcaster.FIGHTING_DE -> 1.2
        Broadcaster.FITE_TV -> 0.9
        Broadcaster.FLX_CAST -> 0.9
        Broadcaster.GLORY_TV -> 0.9
        Broadcaster.GMC_TV -> 0.9
        Broadcaster.IMMAF_TV -> 0.8
        Broadcaster.KSW_TV -> 0.9
        Broadcaster.MEINSPORTPODCAST_DE -> 0.9
        Broadcaster.OKTAGON_TV -> 0.9
        Broadcaster.PLUTO_TV -> 0.9
        Broadcaster.SPORT1_EXTRA -> 0.8
        Broadcaster.SPORTSCHAU_DE -> 0.9
        Broadcaster.UFC_FIGHT_PASS -> 1.2
        Broadcaster.YOUTUBE -> 0.9
        else -> 1.0
    }
}

internal fun Broadcaster.getLogoSize(wide: Boolean = false): Pair<Int, Int>? {
    return when (this) {
        Broadcaster.ARD -> Pair(681, 246)
        Broadcaster.BILD_PLUS -> Pair(90, 46)
        Broadcaster.BILD_TV -> Pair(56, 56)
        Broadcaster.BRAVECF_TV -> Pair(335, 137)
        Broadcaster.DAZN -> Pair(439, 439)
        Broadcaster.EMC_TV -> if (wide) Pair(550, 98) else Pair(433, 98)
        Broadcaster.EUROSPORT -> Pair(600, 70)
        Broadcaster.EUROSPORT2 -> Pair(600, 61)
        Broadcaster.FACEBOOK -> Pair(216, 43)
        Broadcaster.FIGHT24_TV -> if (wide) Pair(607, 110) else Pair(435, 110)
        Broadcaster.FIGHTING_DE -> if (wide) Pair(655, 60) else Pair(152, 152)
        Broadcaster.FITE_TV -> Pair(90, 28)
        Broadcaster.FLX_CAST -> Pair(233, 68)
        Broadcaster.GLORY_TV -> Pair(639, 146)
        Broadcaster.GMC_TV -> Pair(946, 276)
        Broadcaster.IMMAF_TV -> Pair(798, 128)
        Broadcaster.KSW_TV -> Pair(226, 43)
        Broadcaster.MDR -> Pair(1444, 465)
        Broadcaster.MEINSPORTPODCAST_DE -> Pair(191, 63)
        Broadcaster.OKTAGON_TV -> Pair(1148, 165)
        Broadcaster.ONE_TV -> Pair(354, 177)
        Broadcaster.PRO7_MAXX -> Pair(651, 122)
        Broadcaster.RAN_FIGHTING -> Pair(421, 66)
        Broadcaster.PLUTO_TV -> Pair(96, 24)
        Broadcaster.SEGI_TV -> Pair(105, 55)
        Broadcaster.SPORT1 -> Pair(346, 88)
        Broadcaster.SPORT1_EXTRA -> Pair(671, 96)
        Broadcaster.SPORTSCHAU_DE -> Pair(310, 49)
        Broadcaster.UFC_FIGHT_PASS -> if (wide) Pair(1123, 176) else Pair(638, 313)
        Broadcaster.YOUTUBE -> if (wide) Pair(381, 85) else Pair(248, 77)
        Broadcaster.XYZSPORTS_TV -> Pair(588, 121)
        Broadcaster.ZDF -> Pair(658, 260)
        else -> null
    }
}

internal fun Broadcaster.getLogoHeight(width: Double, wide: Boolean = false): Double {
    val dimension = this.getLogoSize(wide) ?: return 0.0
    return (dimension.second.toDouble() * width) / dimension.first.toDouble()
}

internal fun Broadcaster.getLogoWidth(height: Double, wide: Boolean = false): Double {
    val dimension = this.getLogoSize(wide) ?: return 0.0
    return (dimension.first.toDouble() * height) / dimension.second.toDouble()
}
