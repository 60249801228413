package de.kampfkalender.common

import kotlinx.serialization.Serializable

@Serializable
@Suppress("unused")
enum class Broadcaster(
    val title: String,
    val url: String? = null,
    val streamUrl: String? = null,
    val twitter: String? = null,
    val tags: List<String> = listOf(),
    val active: Boolean = true,
) {
    ARD(
        title = "ARD",
        url = "https://daserste.de/",
        streamUrl = "https://live.daserste.de/",
        twitter = "@DasErste",
    ),
    BELLATOR_APP(
        title = "Bellator App",
        url = "https://www.bellator.com/",
        active = false,
    ),
    BILD_PLUS(
        title = "Bild+",
        url = "https://offers.bild.de/",
        twitter = "@BILD_plus",
    ),
    BILD_TV(
        title = "Bild TV",
        url = "https://bild.tv/",
        streamUrl = "https://bild.tv",
    ),
    BRAVECF_TV(
        title = "bravecftv.com",
        url = "https://bravecftv.com/",
        twitter = "#BRAVECFtv",
    ),
    CBS_SPORTS(
        title = "CBS Sports",
        active = false,
        twitter = "@CBSSports",
    ),
    DAZN(
        title = "DAZN",
        twitter = "@DAZN_DE",
        tags = listOf("#DAZNfightclub"),
        url = "https://dazn.com/",
    ),
    EMC_TV(
        title = "emctv.de",
        //twitter = "german-mma.de",
        url = "https://emctv.de/",
    ),
    EUROSPORT(
        title = "Eurosport",
        twitter = "#Eurosport",
        tags = listOf("@Eurosport_DE"),
        url = "https://www.eurosport.de/",
    ),
    EUROSPORT2(
        title = "Eurosport 2",
        twitter = "#Eurosport2",
        tags = listOf("@Eurosport_DE"),
        url = "https://www.eurosport.de/",
    ),
    FACEBOOK(
        title = "Facebook",
        twitter = "#Facebook",
        url = "https://www.facebook.com/",
    ),
    FIGHT24_TV(
        title = "fight24.tv",
        twitter = "@fight24tv",
        url = "https://fight24.tv/",
    ),
    FIGHTING_DE(
        title = "fighting.de",
        twitter = "@fighting_de",
        url = "https://fighting.de/",
        streamUrl = "https://www.youtube.com/c/FIGHTINGde",
    ),
    FITE_TV(
        title = "fite.tv",
        twitter = "@FiteTV",
        url = "https://www.fite.tv/",
    ),
    FLX_CAST(
        title = "FLXcast",
        twitter = "@EagleOnFLX",
        url = "https://goflx.com/",
    ),
    GLORY_TV(
        title = "glory.tv",
        twitter = "gloryfights.com",
        url = "https://gloryfights.com/",
    ),
    GMC_TV(
        title = "gmc.tv",
        twitter = "german-mma.de",
        url = "https://www.german-mma.de/",
    ),
    IMMAF_TV(
        title = "immaf.tv",
        twitter = "immaf.tv",
        url = "https://immaf.tv/"
    ),
    KSW_TV(
        title = "kswtv.com",
        twitter = "kswtv.com",
        url = "https://kswtv.com/",
        streamUrl = "https://kswtv.com/tv/fightingde",
    ),
    MDR(
        title = "MDR",
        twitter = "@mdrde",
        tags = listOf("@SportimOsten"),
        url = "https://www.mdr.de/",
        streamUrl = "https://www.mdr.de/video/livestreams/",
    ),
    MEINSPORTPODCAST_DE(
        title = "meinsportpodcast.de",
        twitter = "@meinsportpod",
        url = "https://meinsportpodcast.de/",
        active = false,
    ),
    OKTAGON_TV(
        title = "oktagon.tv",
        twitter = "oktagon.tv",
        url = "https://oktagon.tv/",
        streamUrl = "https://oktagon.tv/#/ref/fightingde",
    ),
    ONE_APP(
        title = "ONE Super App",
        url = "https://www.onefc.com/download-app/",
        active = false,
    ),
    ONE_TV(
        title = "ONE TV",
        url = "https://watch.onefc.com/"
    ),
    PLUTO_TV(
        title = "Pluto TV",
        twitter = "@PlutoTVDACH",
        tags = listOf("@PlutoTV", "#PlutoTV", "#PlutoTVde"),
        url = "https://pluto.tv/"
    ),
    PRO7_MAXX(
        title = "ProSieben MAXX",
        twitter = "@ProSiebenMAXX",
        url = "https://www.prosiebenmaxx.de/",
        streamUrl = "https://www.prosiebenmaxx.de/livestream",
    ),
    RAN_FIGHTING(
        title = "ranfighting.de",
        twitter = "@ranfightingDE",
        url = "https://ranfighting.de/",
        active = false,
    ),
    SEGI_TV(
        title = "segi.tv",
        twitter = "@Segi_tv",
        url = "https://www.segi.tv/",
        streamUrl = "https://www.segi.tv/",
    ),
    SPORT1(
        title = "SPORT1",
        twitter = "@SPORT1",
        url = "https://www.sport1.de/",
        streamUrl = "https://tv.sport1.de/live/sport1",
    ),
    SPORT1_EXTRA(
        title = "SPORT1 Extra",
        url = "https://www.sport1extra.de/"
    ),
    SPORTSCHAU_DE(
        title = "sportschau.de",
        twitter = "@sportschau",
        url = "https://www.sportschau.de/",
    ),
    UFC_FIGHT_PASS(
        title = "UFC Fight Pass",
        twitter = "@UFCFightPass",
        url = "https://ufcfightpass.com/",
    ),
    YOUTUBE(
        title = "YouTube",
        twitter = "#YouTube",
        url = "https://www.youtube.com/",
    ),
    XYZSPORTS_TV(
        title = "xyzsports.tv",
        twitter = "@xyzsportstv",
        url = "https://xyzsports.tv/",
        active = false,
    ),
    ZDF(
        title = "ZDF",
        twitter = "@ZDF",
        url = "https://www.zdf.de/",
        streamUrl = "https://www.zdf.de/live-tv",
    );

    fun exportName(): String {
        return name.lowercase().replace('_', '-')
    }

    fun icalFileName(): String {
        return "${exportName()}.ics"
    }

    fun rssFileName(): String {
        return "${exportName()}.rss"
    }

    companion object {
        fun parse(value: String): Broadcaster? {
            return values().find {
                it.name.equals(value, true) || it.title.equals(value, true)
            }
        }
    }
}
