package de.kampfkalender.common

import kotlinx.serialization.Serializable

@Serializable
@Suppress("SpellCheckingInspection")
enum class Sport(
    val title: String,
    val tags: List<String> = listOf(),
) {
    MMA(
        title = "MMA",
        tags = listOf("#MMA"),
    ),
    BOXING(
        title = "Boxen",
        tags = listOf("#Boxing", "#Boxen"),
    ),
    BARE_KNUCKLE(
        title = "Bare Knuckle",
        tags = listOf("#BareKnuckle", "#Boxing"),
    ),
    KICKBOXING(
        title = "Kickboxen",
        tags = listOf("#K1", "#Kickboxing", "#Kickboxen"),
    ),
    MUAY_THAI(
        title = "Muay Thai",
        tags = listOf("#MuayThai"),
    ),
    KARATE(
        title = "Karate",
        tags = listOf("#Karate"),
    ),
    LETHWEI(
        title = "Lethwei",
        tags = listOf("#Lethwei"),
    ),
    GRAPPLING(
        title = "Grappling",
        tags = listOf("#Grappling", "#BJJ"),
    ),
    WRESTLING(
        title = "Wrestling",
        tags = listOf("#Wrestling"),
    );

    fun exportName(): String {
        return name.lowercase().replace('_', '-')
    }

    fun icalFileName(): String {
        return "${exportName()}.ics"
    }

    fun rssFileName(): String {
        return "${exportName()}.rss"
    }
}
