package de.kampfkalender.common

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

expect fun defaultDateTime(): DateTime

@Serializable(DateTimeSerializer::class)
data class DateTime(val iso: String): Comparable<DateTime> {
    companion object {
        fun comparator(): Comparator<DateTime> {
            return compareBy {
                it.iso
            }
        }
    }

    override fun compareTo(other: DateTime): Int {
        return comparator().compare(this, other)
    }
}

@OptIn(ExperimentalSerializationApi::class)
class DateTimeSerializer : KSerializer<DateTime> {
    override val descriptor = PrimitiveSerialDescriptor("DateTime", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: DateTime) {
        encoder.encodeString(value.iso)
    }

    override fun deserialize(decoder: Decoder): DateTime {
        return DateTime(decoder.decodeString())
    }
}
