package de.kampfkalender.web.components

import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.web.utils.dateFromYearMonthDay
import de.kampfkalender.web.utils.formatAsIsoDate
import de.kampfkalender.web.utils.toDate
import react.FC
import react.Props
import kotlin.js.Date

external interface ScheduleMonthProps : Props {
    var month: Date
    var events: List<ExportedEvent>
    var onShowEvent: (event: ExportedEvent) -> Unit
}

val ScheduleMonth = FC<ScheduleMonthProps> { props ->
    buildMap {
        props.events.forEach {
            val key = it.time.toDate().formatAsIsoDate()
            this@buildMap.put(
                key,
                this@buildMap.getOrElse(key) { listOf<ExportedEvent>() }.plus(it)
            )
        }
    }.forEach {
        ScheduleDay {
            this.key = it.key
            this.day = dateFromYearMonthDay(it.key)
            this.events = it.value
            this.onShowEvent = { e -> props.onShowEvent(e) }
        }
    }
}
