package de.kampfkalender.web

import de.kampfkalender.web.pages.BroadcasterPage
import de.kampfkalender.web.pages.IndexPage
import de.kampfkalender.web.pages.SubscribePage
import de.kampfkalender.web.utils.Styles
import js.core.jso
import mui.material.Box
import mui.material.Container
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML
import react.router.Outlet
import react.router.PathRoute
import react.router.Routes

val Content = FC<Props> {
    // val showcases = useContext(ShowcasesContext)

    Container {
        Routes {
            PathRoute {
                path = "/"
                element = Box.create {
                    component = ReactHTML.main
                    sx = jso {
                        // flexGrow = FlexGrow(1.0)
                        // padding = 30.px
                        marginTop = Styles.Header.Height
                    }
                    Outlet()
                }

                PathRoute {
                    index = true
                    element = IndexPage.create()
                }

                PathRoute {
                    path = "/broadcaster"
                    element = BroadcasterPage.create()
                }

                PathRoute {
                    path = "/subscribe"
                    element = SubscribePage.create()
                }
            }
        }
    }
}