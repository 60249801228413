package de.kampfkalender.web.utils

import de.kampfkalender.common.ExportSummary
import kotlin.js.Date

internal fun ExportSummary.getMaxDate(): Date? {
    return if (this.dataEnd != null) {
        dateFromYearMonth(this.dataEnd, true).addMonths(1).addDays(-1)
    } else {
        null
    }
}

internal fun ExportSummary.getMinDate(): Date? {
    return if (this.dataStart != null) {
        dateFromYearMonth(this.dataStart, true)
    } else {
        null
    }
}

internal fun ExportSummary.getMissingDates(): List<Date> {
    return this.dataMissing.map {
        dateFromYearMonth(it, true)
    }
}
