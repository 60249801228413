package de.kampfkalender.web

import de.kampfkalender.common.ExportSummary
import de.kampfkalender.web.utils.DARK_THEME_OPTIONS
import de.kampfkalender.web.utils.LIGHT_THEME_OPTIONS
import de.kampfkalender.web.utils.PREFER_DARK_MEDIA_QUERY
import de.kampfkalender.web.utils.toDateTime
import js.core.jso
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import mui.material.Box
import mui.material.CircularProgress
import mui.material.CssBaseline
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import react.FC
import react.Props
import react.StrictMode
import react.create
import react.createContext
import react.dom.client.createRoot
import react.router.dom.BrowserRouter
import react.useEffectOnce
import react.useMemo
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.vh
import web.dom.document
import kotlin.js.Date
import kotlin.js.Promise

private var SERVICE_WORKER_REGISTERED = false
internal fun isServiceWorkerRegistered(): Boolean {
    return SERVICE_WORKER_REGISTERED
}

private var APP_INSTALLER: dynamic = null

//internal fun isAppInstallable(): Boolean {
//    return APP_INSTALLER != null
//}

//private var APP_INSTALLED = false
//internal fun isAppInstalled(): Boolean {
//    return APP_INSTALLED
//}

fun main() {
    window.addEventListener("beforeinstallprompt", { e ->
        e.preventDefault()
        //console.log("APP IS INSTALLABLE")
        APP_INSTALLER = e
    })

    //window.addEventListener("appinstalled", { e ->
    //    e.preventDefault()
    //    console.log("APP IS INSTALLED")
    //    APP_INSTALLED = true
    //})

    try {
        window.navigator.serviceWorker
            .register("/kampfkalender-worker.js")
            .then {
                SERVICE_WORKER_REGISTERED = true
                // console.log("Service worker registered!", it)
            }
            .catch {
                SERVICE_WORKER_REGISTERED = false
                console.error("Can't register service worker!", it)
            }
    } catch (e: Throwable) {
        console.error("Can't install service worker!", e)
    }

    createRoot(
        document.createElement("div")
            .also {
                document.body.appendChild(it)
            }
    ).render(
        App.create()
    )
}

private fun loadSummary(): Promise<ExportSummary> {
    return window.fetch("/data/summary.json")
        .then {
            it.text()
        }
        .then {
            Json.decodeFromString<ExportSummary>(it)
        }
        .catch {
            ExportSummary(
                lastUpdate = Date().toDateTime()
            )
        }
}

private fun isDarkModeSelected(): Boolean {
    val darkModeSelected = localStorage.getItem("darkMode")?.lowercase()
    if (darkModeSelected == null || !listOf("true", "false").contains(darkModeSelected)) {
        return window.matchMedia(PREFER_DARK_MEDIA_QUERY).matches
    }
    return true.toString().lowercase() == darkModeSelected
}

data class ApplicationContextData(
    val summary: ExportSummary?,
    val sidebarOpened: Boolean,
    val setSidebarOpened: (Boolean) -> Unit,
    val darkMode: Boolean,
    val setDarkMode: (Boolean) -> Unit,
    val canInstall: () -> Boolean = {
        APP_INSTALLER != null
    },
    val doInstall: () -> Promise<Boolean>? = doInstall@{
        if (APP_INSTALLER == null) {
            return@doInstall null
        }
        return@doInstall (APP_INSTALLER.prompt() as Promise<dynamic>)
            .then { it: dynamic -> return@then it.outcome == "accepted" }
    },
)

val ApplicationContext = createContext<ApplicationContextData>()

private val App = FC<Props> {
    val (summary, summarySetter) = useState<ExportSummary?>(null)
    useEffectOnce {
        loadSummary()
            .then {
                summarySetter(it)
                // window.setTimeout({ summarySetter(it) }, 1000 )
            }
    }

    // Sidebar state handling.
    val (sidebarOpened, sidebarOpenedSetter) = useState(false)
    val setSidebarOpened: (Boolean) -> Unit = { sidebarOpenedSetter(it) }

    // Dark-Mode state handling.
    val (darkMode, darkModeSetter) = useState(isDarkModeSelected())
    val setDarkMode: (Boolean) -> Unit = {
        localStorage.setItem("darkMode", it.toString())
        darkModeSetter(it)
    }

    // Create Material theme based on current Dark-Mode state.
    val selectedTheme = useMemo(darkMode) {
        createTheme(
            if (darkMode) {
                DARK_THEME_OPTIONS
            } else {
                LIGHT_THEME_OPTIONS
            }
        )
    }

    // Create application context.
    val context = useMemo(summary, sidebarOpened, darkMode) {
        if (summary == null) {
            null
        } else {
            ApplicationContextData(
                summary = summary,
                sidebarOpened = sidebarOpened,
                setSidebarOpened = setSidebarOpened,
                darkMode = darkMode,
                setDarkMode = setDarkMode,
            )
        }
    }

    // Build application.
    StrictMode {
        ThemeProvider {
            theme = selectedTheme
            CssBaseline {
                enableColorScheme = true

                BrowserRouter {
                    if (summary == null) {
                        Box {
                            sx = jso {
                                display = Display.flex
                                height = 100.vh
                                justifyContent = JustifyContent.center
                                alignItems = AlignItems.center
                            }
                            CircularProgress()
                        }
                    } else {
                        ApplicationContext.Provider(context!!) {
                            Box {
                                Header()
                                Sidebar()
                                Content()
                            }
                        }
                    }
                }
            }
        }
    }
}
