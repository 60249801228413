package de.kampfkalender.web.components

import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.Promotion
import de.kampfkalender.common.Sport
import de.kampfkalender.web.utils.BROADCASTERS
import de.kampfkalender.web.utils.Filter
import de.kampfkalender.web.utils.PROMOTIONS
import de.kampfkalender.web.utils.SPORTS
import js.core.jso
import mui.base.BlurOnSelect
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogScroll
import mui.material.DialogTitle
import mui.material.FormControlLabel
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Switch
import mui.material.TextField
import mui.system.responsive
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.useEffect
import react.useState
import web.cssom.None

external interface FilterDialogProps : Props {
    var create: Boolean?
    var open: Boolean?
    var filter: Filter
    var onChange: (filter: Filter) -> Unit
    var onClose: () -> Unit
}

val FilterDialog = FC<FilterDialogProps> { props ->
    val (error, setError) = useState(false)
    val (promotions, setPromotions) = useState<List<Promotion>>(listOf())
    val (broadcasters, setBroadcasters) = useState<List<Broadcaster>>(listOf())
    val (sports, setSports) = useState<List<Sport>>(listOf())
    val (free, setFree) = useState(false)

    useEffect(props.create, props.filter) {
        setPromotions(buildList {
            if (props.create == false) {
                addAll(props.filter.promotions)
            }
        })
        setBroadcasters(buildList {
            if (props.create == false) {
                addAll(props.filter.broadcasters)
            }
        })
        setSports(buildList {
            if (props.create == false) {
                addAll(props.filter.sports)
            }
        })
        if (props.create == false) {
            setFree(props.filter.free)
        }
    }

    val closeDialog: () -> Unit = {
        props.onClose()
        setError(false)
        setPromotions(listOf())
        setBroadcasters(listOf())
        setSports(listOf())
        setFree(false)
    }

    Dialog {
        open = props.open == true
        keepMounted = false
        maxWidth = "md"
        fullWidth = true
        scroll = DialogScroll.paper
        onClose = { _, _ -> closeDialog() }

        DialogTitle {
            @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
            this as react.PropsWithChildren

            if (props.create == true) {
                +"Filter erstellen"
            } else {
                +"Filter bearbeiten"
            }
        }

        DialogContent {
            dividers = true

            Stack {
                spacing = responsive(2)
                direction = responsive(StackDirection.column)

                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Promotion>> {
                    value = promotions.toTypedArray()
                    multiple = true
                    limitTags = 2
                    options = PROMOTIONS.toTypedArray()
                    disablePortal = false
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Veranstalter gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Veranstalter")
                        }
                    }
                    getOptionLabel = {
                        if (it != Promotion._OTHER && it.acronym != it.title) {
                            "${it.acronym}: ${it.title}"
                        } else {
                            it.title
                        }
                    }
                    onChange = { _, value: Array<Promotion>, _, _ ->
                        // console.log("NEW PROMOTION", value)
                        setPromotions(value.toList())
                    }
                }

                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Broadcaster>> {
                    value = broadcasters.toTypedArray()
                    multiple = true
                    limitTags = 2
                    options = BROADCASTERS.toTypedArray()
                    disablePortal = false
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Sender gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Sender")
                        }
                    }
                    getOptionLabel = { it.title }
                    onChange = { _, value: Array<Broadcaster>, _, _ ->
                        //console.log("NEW BROADCASTER", value)
                        setBroadcasters(value.toList())
                    }
                }

                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Sport>> {
                    value = sports.toTypedArray()
                    multiple = true
                    limitTags = 2
                    options = SPORTS.toTypedArray()
                    disablePortal = false
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Sportarten gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Sportart")
                        }
                    }
                    getOptionLabel = { it.title }
                    onChange = { _, value: Array<Sport>, _, _ ->
                        //console.log("NEW SPORT", value)
                        setSports(value.toList())
                    }
                }

                Box {
                    FormControlLabel {
                        label = ReactNode("nur kostenlose Events anzeigen")
                        control = Switch.create {
                            checked = free
                            onChange = { _, checked: Boolean -> setFree(checked) }
                        }
                    }
                }

                Alert {
                    sx = jso {
                        if (!error) {
                            display = None.none
                        }
                    }

                    severity = AlertColor.warning

                    +"Bitte wähle mindestens eine Sportart, einen Sender oder einen Veranstalter aus."
                }
            }
        }

        DialogActions {
            Button {
                onClick = {
                    closeDialog()
                }

                +"Abbrechen"
            }

            Button {
                onClick = {
                    if (promotions.isEmpty() && broadcasters.isEmpty() && sports.isEmpty()) {
                        setError(true)
                    } else {
                        props.onChange(
                            if (props.create == false) {
                                props.filter
                            } else {
                                Filter()
                            }.copy(
                                promotions = promotions,
                                broadcasters = broadcasters,
                                sports = sports,
                                free = free,
                            )
                        )
                        closeDialog()
                    }
                }

                +"Übernehmen"
            }
        }
    }
}
