package de.kampfkalender.common

import kotlinx.serialization.Serializable

@Serializable
@Suppress("SpellCheckingInspection")
enum class Promotion(
    val title: String,
    val acronym: String,
    val podcast: Boolean = false,
    val active: Boolean = true,
    val tapologyUrl: String? = null,
    val streamUrl: String? = null,
    val sports: List<Sport> = listOf(),
    val tags: List<String> = listOf(),
    val mainCardFree: Boolean = false,
    val mainCardBroadcaster: List<Broadcaster> = listOf(),
    val prelimsFree: Boolean = false,
    val prelimsBroadcaster: List<Broadcaster> = listOf(),
    val earlyPrelimsFree: Boolean = false,
    val earlyPrelimsBroadcaster: List<Broadcaster> = listOf(),
) {
    @Suppress("EnumEntryName")
    _OTHER(
        title = "Sonstige",
        acronym = "Other",
    ),
    A1C(
        title = "Urijah Faber's A1 Combat",
        acronym = "A1C",
        sports = listOf(Sport.MMA),
        tags = listOf("#A1C"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4276-urijah-faber-s-a1-combat-a1c",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    AKA(
        title = "American Kombat Alliance",
        acronym = "AKA",
        sports = listOf(Sport.MMA),
        tags = listOf("#AKA", "@AKA_Fights"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2150-american-kombat-alliance-aka",
        mainCardBroadcaster = listOf(Broadcaster.FITE_TV),
    ),
    ALASKA(
        title = "Alaska Fighting Championship",
        acronym = "AFC",
        sports = listOf(Sport.MMA),
        tags = listOf("@AlaskaFighting"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/245-alaska-fighting-championship-afc",
    ),
    AMT(
        title = "Absolute Muay Thai",
        acronym = "AMT",
        sports = listOf(Sport.MUAY_THAI),
        tags = listOf("#AbsoluteMuayThai"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3218-absolute-muay-thai-amt",
    ),
    APFC(
        title = "Showtime Fighting Championship",
        acronym = "APFC",
        tags = listOf("#APFC", "@showtimefcmma"),
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4224-showtime-fighting-championship-sfc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    ARES(
        title = "ARES Fighting Championship",
        acronym = "ARES",
        sports = listOf(Sport.MMA),
        tags = listOf("@ares_fighting"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3121-ares-fighting-championship-afc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    ASE_BOXING(
        title = "Agon Sports Events",
        acronym = "ASE",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3208-agon-sports-events-ase",
    ),
    AUSTRIAN(
        title = "Austrian Fight Challenge",
        acronym = "AFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1643-austrian-fight-challenge-afc",
    ),
    BDB_BOXING(
        title = "Bund Deutscher Berufsboxer",
        acronym = "BDB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@bdbbox"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3301-bund-deutscher-berufsboxer-bdb",
    ),
    BELLATOR(
        title = "Bellator MMA",
        acronym = "Bellator",
        sports = listOf(Sport.MMA),
        tags = listOf("@BellatorMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2-bellator-fighting-championships-bfc",
        streamUrl = "https://pluto.tv/de/live-tv/bellator-mma-de",
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.PLUTO_TV),
        prelimsFree = true,
        prelimsBroadcaster = listOf(Broadcaster.PLUTO_TV),
    ),
    BFL(
        title = "Battlefield Fight League",
        acronym = "BFL",
        sports = listOf(Sport.MMA),
        tags = listOf("#BFLMMA", "@battlefieldfl"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/105-battlefield-fight-league-bfl",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    BG_DE(
        title = "Big Game",
        acronym = "BGMMA",
        sports = listOf(Sport.MMA, Sport.KICKBOXING),
        tags = listOf("#BigGame", "#BGMMA", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2268-big-game-bgmma",
    ),
    BKB(
        title = "Bare Knuckle Boxing",
        acronym = "BKB",
        sports = listOf(Sport.BARE_KNUCKLE),
        tags = listOf("#BKB", "@bkb_official1"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4236-bare-knuckle-boxing-bkb",
        mainCardBroadcaster = listOf(Broadcaster.FITE_TV),
    ),
    BKFC(
        title = "Bare Knuckle Fighting Championship",
        acronym = "BKFC",
        sports = listOf(Sport.BARE_KNUCKLE),
        tags = listOf("#BKFC", "@bareknucklefc"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2682-bare-knuckle-fighting-championship-bnfc",
        mainCardBroadcaster = listOf(Broadcaster.FITE_TV),
        prelimsFree = true,
        prelimsBroadcaster = listOf(Broadcaster.FITE_TV),
    ),
    BRAVE(
        title = "Brave Combat Federation",
        acronym = "BRAVE",
        sports = listOf(Sport.MMA),
        tags = listOf("#BraveCF", "#BraveNation", "@bravemmaf"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1782-brave-combat-federation-bcf",
        //mainCardBroadcaster = listOf(),
    ),
    BSC_MX(
        title = "Budo Sento Championship",
        acronym = "BSC",
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3712-budo-sento-championship-bsc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    CAGE(
        title = "CAGE MMA Finland",
        acronym = "CAGE",
        sports = listOf(Sport.MMA),
        tags = listOf("@cagemmafinland"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/394-cage-mma-finland-cage",
        //mainCardBroadcaster = listOf(),
    ),
    CES(
        title = "Classic Entertainment & Sports MMA",
        acronym = "CES",
        sports = listOf(Sport.MMA),
        tags = listOf("@CESMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/36-classic-entertainment-sports-mma-ces",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    CES_BOXING(
        title = "CES Boxing",
        acronym = "CESB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@cesboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2521-ces-boxing-cesb",
    ),
    CFFC(
        title = "Cage Fury Fighting Championships",
        acronym = "CFFC",
        sports = listOf(Sport.MMA),
        tags = listOf("@CFFCMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/57-cage-fury-fighting-championships-cffc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    CFS_AT(
        title = "Cage Fight Series",
        acronym = "CFS",
        sports = listOf(Sport.MMA),
        tags = listOf("#CFS", "#MMAustria", "#EuroMMA", "@cage_series"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2661-cage-fight-series-cfs",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    CG(
        title = "Combate Global",
        acronym = "CG",
        sports = listOf(Sport.MMA),
        tags = listOf("#CombateGlobal", "@combateglobal"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3872-combate-global-cg",
        active = false,
    ),
    CP_BOXING(
        title = "Canelo Promotions",
        acronym = "CP",
        sports = listOf(Sport.BOXING),
        tags = listOf("@canelopromotion"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2747-canelo-promotions-cp",
    ),
    CR_PODCAST(
        title = "Championship Rounds Podcast",
        acronym = "CR",
        podcast = true,
        tags = listOf("#DAZNfightclub", "#GerMMAny", "@SebastianHackl", "@FloMMAndavid"),
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
    ),
    CWFC(
        title = "Cage Warriors Fighting Championship",
        acronym = "CWFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#CageWarriors", "#UKMMA", "#EuroMMA", "@CageWarriors"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/55-cage-warriors-fighting-championship-cwfc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
        prelimsBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    DBE_BOXING(
        title = "DiBella Entertainment",
        acronym = "DBE",
        sports = listOf(Sport.BOXING),
        tags = listOf("@loudibella"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2724-dibella-entertainment-dbe",
    ),
    DWCS(
        title = "Dana White's Contender Series",
        acronym = "DWCS",
        sports = listOf(Sport.MMA),
        tags = listOf("#DWCS", "@ufc"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2026-dana-whites-contender-series-dwcs",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    EC_BOXING(
        title = "ECBoxing",
        acronym = "ECB",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2573-ecboxing-ecb",
    ),
    EBI(
        title = "Eddie Bravo Invitational",
        acronym = "EBI",
        sports = listOf(Sport.GRAPPLING),
        tags = listOf("@eddiebravo"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2670-eddie-bravo-invitational-ebi",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    EFC_DE(
        title = "Extreme Fight Club",
        acronym = "EFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EFC", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1805-extreme-fight-club-efc",
    ),
    EFC_RU(
        title = "Eagle FC",
        acronym = "EFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EagleFC", "@EagleFightClub"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3741-eagle-fighting-championship-efc",
    ),
    EFM_PL(
        title = "European Fight Masters",
        acronym = "EFM",
        sports = listOf(Sport.MMA),
        tags = listOf("#EFM", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1300-european-fight-masters-efm",
    ),
    EMC_DE(
        title = "ELITE MMA Championship",
        acronym = "EMC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EMC", "#GerMMAny", "@EMC_Promotion"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2869-elite-mma-championship-emc",
        mainCardBroadcaster = listOf(Broadcaster.EMC_TV),
        prelimsBroadcaster = listOf(Broadcaster.EMC_TV),
    ),
    ENFUSION(
        title = "Enfusion",
        acronym = "Enfusion",
        sports = listOf(Sport.KICKBOXING),
        tags = listOf("#Enfusion", "@EnfusionLIVE"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3002-enfusion-cage-events-ece",
    ),
    ETERNAL(
        title = "Eternal MMA",
        acronym = "EMMA",
        sports = listOf(Sport.MMA),
        tags = listOf("#eternal", "@eternal_mma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/709-eternal-mma-emma",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FAC(
        title = "Fighting Alliance Championship",
        acronym = "FAC",
        sports = listOf(Sport.MMA),
        tags = listOf("#FAC"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3062-fighting-alliance-championship-fac",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FAIR(
        title = "Fair Fighting Championship",
        acronym = "Fair",
        sports = listOf(Sport.MMA),
        tags = listOf("#FairFC", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/835-fair-fighting-championship-ffc",
    ),
    FCC_UK(
        title = "Full Contact Contender",
        acronym = "FCC",
        sports = listOf(Sport.MMA),
        tags = listOf("#FCC", "@fccmma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/581-full-contact-contender-fcc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FCR(
        title = "Fight Club Rush",
        acronym = "FCR",
        sports = listOf(Sport.MMA),
        tags = listOf("#FCR", "#FCRMMA", "#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2373-fight-club-rush-fcr",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FFP(
        title = "Fighting Force Promotions",
        acronym = "FFP",
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2973-fighting-force-promotions-ffp",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FURY(
        title = "Fury Fighting Championship",
        acronym = "FFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#FuryFC", "@FuryFightingTX"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/715-fury-fighting-championship-ffc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    FUSION(
        title = "Fusion Fight League",
        acronym = "FFL",
        sports = listOf(Sport.MMA),
        tags = listOf("#FFL", "@fflmma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1350-fusion-fight-league-ffl",
        mainCardBroadcaster = listOf(Broadcaster.FITE_TV),
    ),
    GBP_BOXING(
        title = "Golden Boy Promotions",
        acronym = "GBP",
        sports = listOf(Sport.BOXING),
        tags = listOf("@goldenboyboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1979-golden-boy-promotions-gbp",
        mainCardBroadcaster = listOf(Broadcaster.DAZN),
    ),
    GCP_DE(
        title = "German Cage Pioneer",
        acronym = "GCP",
        sports = listOf(Sport.MMA),
        tags = listOf("#GCP", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3652-german-cage-pioneer-gcp",
    ),
    GEMMAF(
        title = "German Mixed Martial Arts Federation",
        acronym = "GEMMAF",
        sports = listOf(Sport.MMA),
        tags = listOf("#Amateure", "#GEMMAF", "#GerMMAny", "@_GEMMAF_"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2325-german-mixed-martial-arts-federation-gemmaf",
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.FIGHTING_DE),
    ),
    GLORY(
        title = "Glory Kickboxing",
        acronym = "Glory",
        sports = listOf(Sport.KICKBOXING),
        tags = listOf("#GLORY", "@GLORY_WS"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2605-glory-kickboxing-gk",
        mainCardBroadcaster = listOf(Broadcaster.FIGHTING_DE, Broadcaster.GLORY_TV),
        prelimsBroadcaster = listOf(Broadcaster.FIGHTING_DE, Broadcaster.GLORY_TV),
    ),
    GMC_DE(
        title = "German MMA Championship",
        acronym = "GMC",
        sports = listOf(Sport.MMA),
        tags = listOf("#GMC", "#GerMMAny", "@GMC_MMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/455-german-mma-championship-gmc",
        mainCardBroadcaster = listOf(Broadcaster.GMC_TV),
    ),
    HFC_CH(
        title = "Hard Fighting Championship",
        acronym = "HFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/562-hard-fighting-championship-hfc",
    ),
    HYPE(
        title = "Hype Fighting Championship",
        acronym = "Hype",
        sports = listOf(Sport.MMA),
        tags = listOf("#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1745-hype-fighting-championship-hfc",
    ),
    HXMMA(
        title = "Hexagone MMA",
        acronym = "HXMMA",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA", "@HexagoneMma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3927-hexagone-mma-hxmma",
    ),
    IKON_FC(
        title = "iKON Fighting Championship",
        acronym = "iKON",
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4230-ikon-fc-ikon",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    IKON_FF(
        title = "iKON Fighting Federation",
        acronym = "iKON",
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3639-ikon-fighting-federation-ikon",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    IMMAF(
        title = "International Mixed Martial Arts Federation",
        acronym = "IMMAF",
        sports = listOf(Sport.MMA),
        tags = listOf("#Amateure", "#IMMAF", "@IMMAFed"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1003-international-mixed-martial-arts-federation-immaf",
        mainCardBroadcaster = listOf(Broadcaster.IMMAF_TV),
    ),
    INNFERNO(
        title = "INNFERNO Fighting Championship",
        acronym = "INNFERNO",
        sports = listOf(Sport.MMA),
        tags = listOf("#INNFERNO", "#MMAustria", "#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1361-innferno-fighting-championship-innferno",
        mainCardBroadcaster = listOf(Broadcaster.FIGHTING_DE),
    ),
    INTEGRA(
        title = "Integra Fighting Championship",
        acronym = "Integra",
        sports = listOf(Sport.MMA),
        tags = listOf("#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1424-integra-fighting-championship-ifc",
    ),
    INVICTA(
        title = "Invicta Fighting Championships",
        acronym = "Invicta",
        sports = listOf(Sport.MMA),
        tags = listOf("#WMMA", "@InvictaFights"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/128-invicta-fighting-championships-ifc",
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
    ),
    JCK_CN(
        title = "Jue Cheng King",
        acronym = "JCK",
        sports = listOf(Sport.MMA),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3688-jue-cheng-king-jck",
    ),
    KC(
        title = "Karate Combat",
        acronym = "KC",
        sports = listOf(Sport.KARATE),
        tags = listOf("#KC", "#KarateCombat", "@KarateCombat"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3637-karate-combat-kc",
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
        streamUrl = "https://www.youtube.com/@KarateCombat",
    ),
    KOK_LT(
        title = "King of Kings",
        acronym = "KOK",
        sports = listOf(Sport.MMA, Sport.KICKBOXING),
        tags = listOf("#KOK", "@kokworldgp"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1668-king-of-kings-kok",
        mainCardBroadcaster = listOf(Broadcaster.DAZN),
    ),
    KSW(
        title = "Konfrontacja Sztuk Walki",
        acronym = "KSW",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA", "@KSW_MMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/9-konfrontacja-sztuk-walki-ksw",
        mainCardBroadcaster = listOf(Broadcaster.KSW_TV),
    ),
    LFA(
        title = "Legacy Fighting Alliance",
        acronym = "LFA",
        sports = listOf(Sport.MMA),
        tags = listOf("#LFANation", "@LFAfighting"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1815-legacy-fighting-alliance-lfa",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    LFL_NL(
        title = "Levels Fight League",
        acronym = "LFL",
        sports = listOf(Sport.MMA),
        tags = listOf("#LFL", "#EuroMMA", "@LFL_MMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3869-levels-fight-league-lfl",
    ),
    LION(
        title = "Lion Fight Promotions",
        acronym = "LFP",
        sports = listOf(Sport.MUAY_THAI),
        tags = listOf("#LionFight", "@lionfight"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3304-lion-fight-promotions-lfp",
    ),
    LOL_BOXING(
        title = "Legends Only League",
        acronym = "LOL",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3719-legends-only-league-lol",
    ),
    LSM_BOXING(
        title = "Legacy Sports Management",
        acronym = "LSM",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4036-legacy-sports-management-lsm",
    ),
    LUX_MX(
        title = "Lux Fight League",
        acronym = "LUX",
        sports = listOf(Sport.MMA),
        tags = listOf("#LUXTIME", "@luxfightleague"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2240-lux-fight-league-lfl",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    M1(
        title = "M-1 Global",
        acronym = "M1",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA", "@M1GlobalNews"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/24-m-1-global-m-1",
        active = false,
    ),
    MB_BOXING(
        title = "Matchroom Boxing",
        acronym = "MB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@matchroomboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2484-matchroom-boxing-mb",
        mainCardBroadcaster = listOf(Broadcaster.DAZN),
    ),
    MF_BOXING(
        title = "Misfits Boxing",
        acronym = "MF",
        sports = listOf(Sport.BOXING),
        tags = listOf("@misfitsboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4502-misfits-boxing-mf",
        mainCardBroadcaster = listOf(Broadcaster.DAZN),
    ),
    MFG_DE(
        title = "Mix Fight Gala",
        acronym = "MFG",
        sports = listOf(Sport.MMA, Sport.KICKBOXING),
        tags = listOf("#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/314-darmstadt-tempel-fightschool-tfs",
    ),
    MMAGP_FR(
        title = "Mixed Martial Arts Grand Prix",
        acronym = "MMAGP",
        sports = listOf(Sport.MMA),
        tags = listOf("#MMAGP", "#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3820-mixed-martial-arts-grand-prix-mmagp",
    ),
    MMAL_DE(
        title = "MMA Live",
        acronym = "MMAL",
        sports = listOf(Sport.MMA),
        tags = listOf("#MMALive", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3070-mma-live-mmal",
    ),
    MMAU_PODCAST(
        title = "MMA Unfolded Podcast",
        acronym = "MMAU",
        podcast = true,
        tags = listOf("#MMA", "#MMAUnfolded", "#GerMMAny", "#DAZNfightclub", "@MMAUnfolded"),
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
    ),
    MP_BOXING(
        title = "Mayweather Promotion",
        acronym = "MP$",
        sports = listOf(Sport.BOXING),
        tags = listOf("@MayweatherPromo"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2626-mayweather-promotion-mp",
    ),
    MPL_UZ(
        title = "Muradov Professional League",
        acronym = "MPL",
        sports = listOf(Sport.MMA),
        tags = listOf("#MPL"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4117-muradov-professional-league-mpl",
    ),
    MTGP_UK(
        title = "Muay Thai Grand Prix",
        acronym = "MTGP",
        sports = listOf(Sport.MUAY_THAI),
        tags = listOf("#GrandPrix", "@MuayThaiGP"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3662-muay-thai-grand-prix-mtgp",
    ),
    MURPHYS_BOXING(
        title = "Murphy's Boxing",
        acronym = "MB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@murphysboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2738-murphy-s-boxing-mb",
    ),
    MVP_BOXING(
        title = "Most Valuable Promotions",
        acronym = "MVP",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4040-most-valuable-promotions-mvp",
    ),
    NFC_DE(
        title = "National Fighting Championship",
        acronym = "NFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#NFC", "#NFCMMA", "#GerMMAny", "#DeFighter"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3649-national-fighting-championship-nfc",
        mainCardBroadcaster = listOf(Broadcaster.FIGHTING_DE),
        prelimsFree = true,
        prelimsBroadcaster = listOf(Broadcaster.FIGHTING_DE),
    ),
    NFG_BY(
        title = "New Fighting Generation",
        acronym = "NFG",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2431-new-fighting-generation-nfg",
    ),
    OFC_RU(
        title = "Open Fighting Championship",
        acronym = "OFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA", "#FromRussiaWithLove"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3745-open-fighting-championship-ofc",
        active = false,
    ),
    OKTAGON(
        title = "Oktagon MMA",
        acronym = "Oktagon",
        sports = listOf(Sport.MMA, Sport.KICKBOXING),
        tags = listOf("#EuroMMA", "@oktagonofficial"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1964-okatgon-mma-okmma",
        mainCardBroadcaster = listOf(Broadcaster.OKTAGON_TV),
    ),
    ONE(
        title = "ONE Championship",
        acronym = "ONE",
        sports = listOf(Sport.MMA, Sport.KICKBOXING, Sport.MUAY_THAI),
        tags = listOf("#WeAreONE", "@ONEChampionship"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/61-one-fighting-championship-ofc",
        streamUrl = "https://www.youtube.com/c/ONEChampionship",
        mainCardFree = true,
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
    ),
    PANCRASE(
        title = "Pancrase",
        acronym = "Pancrase",
        sports = listOf(Sport.MMA),
        tags = listOf("@Pancrase_MMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/144-pancrase-hybrid-wrestling",
        active = false
    ),
    PB_BOXING(
        title = "Probellum",
        acronym = "PB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@probellum"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4161-probellum-pb",
    ),
    PFL(
        title = "Professional Fighters League",
        acronym = "PFL",
        sports = listOf(Sport.MMA),
        tags = listOf("@PFLMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1969-professional-fighters-league-pfl",
        mainCardBroadcaster = listOf(Broadcaster.DAZN),
    ),
    POLARIS(
        title = "Polaris",
        acronym = "Polaris",
        sports = listOf(Sport.GRAPPLING),
        tags = listOf("#Polaris", "@PolarisProEvent"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2673-polaris-ppjji",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    QB_BOXING(
        title = "Queensberry Promotions",
        acronym = "QB",
        sports = listOf(Sport.BOXING),
        tags = listOf("#Queensberry", "@FrankWarren"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2574-queensbury-promotions-qp",
    ),
    QUINTET(
        title = "Quintet",
        acronym = "Quintet",
        sports = listOf(Sport.GRAPPLING),
        tags = listOf("#QUINTET", "@quintet_pr"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2285-quintet-mma-qmma",
    ),
    RCC(
        title = "RCC Boxing Promotions",
        acronym = "RCC",
        sports = listOf(Sport.MMA, Sport.BOXING, Sport.KICKBOXING),
        tags = listOf("#EuroMMA", "#FromRussiaWithLove"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2165-rcc-boxing-promotions-rcc",
    ),
    RESPECT(
        title = "Respect Fighting Championship",
        acronym = "Respect",
        sports = listOf(Sport.MMA),
        tags = listOf("#RESPECT", "GerMMAny", "@RESPECTFC"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/338-respect-fighting-championship-rfc",
        streamUrl = "https://www.youtube.com/channel/UC5p9hgNJAGrAMuYvl5hdCmw",
    ),
    RIZIN(
        title = "RIZIN Fighting Federation",
        acronym = "RIZIN",
        sports = listOf(Sport.MMA),
        tags = listOf("#RIZIN", "@rizin_pr"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1561-rizin-fighting-federation-rff",
    ),
    RJJ_BOXING(
        title = "Roy Jones Jr. Boxing Promotions",
        acronym = "RJJ",
        sports = listOf(Sport.BOXING),
        tags = listOf("@RoyJonesJrFA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2645-roy-jones-jr-boxing-promotions-rjjbp",
        //mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    SAUERLAND_BOXING(
        title = "Sauerland Promotion",
        acronym = "SP",
        sports = listOf(Sport.BOXING),
        tags = listOf("@SauerlandBros"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2622-sauerland-promotion-sp",
    ),
    SCHLAGWORT(
        title = "Schlagwort Podcast",
        acronym = "Schlagwort",
        podcast = true,
        streamUrl = "https://www.youtube.com/c/FIGHTINGde",
        tags = listOf("#SchlagwortNation", "#DAZNfightclub", "#GerMMAny", "@fighting_de"),
        mainCardBroadcaster = listOf(Broadcaster.YOUTUBE),
    ),
    SES_BOXING(
        title = "SES Sports Events",
        acronym = "SES",
        sports = listOf(Sport.BOXING),
        tags = listOf("@SESBoxen"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2520-ses-sports-events-ses",
    ),
    SB_BR(
        title = "Shooto Brasil",
        acronym = "SB",
        sports = listOf(Sport.MMA),
        tags = listOf("#Shooto", "#ShootoBrasil", "@shooto_brasil"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/389-shooto-brasil-sb",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    SHP_GRAPPLING(
        title = "Submission Hunter Pro",
        acronym = "SHP",
        sports = listOf(Sport.GRAPPLING),
        tags = listOf("#SHP", "@SubHunterPro"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3684-submission-hunter-pro-shp",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    SLMMA_DE(
        title = "Super League MMA",
        acronym = "SLMMA",
        sports = listOf(Sport.MMA),
        tags = listOf("#SuperLeague", "#GerMMAny"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3645-super-league-mma-slmma",
        mainCardBroadcaster = listOf(Broadcaster.FIGHTING_DE),
    ),
    SUG_GRAPPLING(
        title = "Submission Underground",
        acronym = "SUG",
        sports = listOf(Sport.GRAPPLING),
        tags = listOf("#SUG", "@SUG_Grappling"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2861-submission-underground-sug",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    TFC_BOXING(
        title = "Triller Fight Club",
        acronym = "TFC",
        sports = listOf(Sport.BOXING),
        tags = listOf("@trillerfight"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3862-triller-fight-club-tfc",
        mainCardBroadcaster = listOf(Broadcaster.FITE_TV),
    ),
    TGB_BOXING(
        title = "TGB Promotions",
        acronym = "TGB",
        sports = listOf(Sport.BOXING),
        tags = listOf("@TGBpromotions"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2582-tgb-promotions",
    ),
    TGC_IT(
        title = "The Golden Cage",
        acronym = "TGC",
        sports = listOf(Sport.MMA),
        tags = listOf("#TGC", "#EuroMMA", "@tgc_mma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2336-the-golden-cage-tgc",
    ),
    TITAN(
        title = "Titan Fighting Championship",
        acronym = "TFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#TitanFC", "@TitanFighting"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/58-titan-fighting-championship-tfc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    TP_BOXING(
        title = "Teiken Promotions",
        acronym = "TP",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2594-teiken-promotions-tp",
    ),
    TR_BOXING(
        title = "Top Rank",
        acronym = "TR",
        sports = listOf(Sport.BOXING),
        tags = listOf("@trboxing"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2487-top-rank-tr",
    ),
    TRINITY(
        title = "Trinity Sport Combat",
        acronym = "TSC",
        sports = listOf(Sport.MMA),
        tags = listOf("#TK", "@ostovich"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1990-trinity-combat-sport-tcs",
    ),
    TSSM_BOXING(
        title = "TS Sports Management",
        acronym = "TSSM",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3260-ts-sports-management-tssm",
    ),
    TTS_CH(
        title = "Time To Shine",
        acronym = "TTS",
        sports = listOf(Sport.MMA),
        tags = listOf("#EuroMMA", "@timetoshineMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1684-time-to-shine-tts",
    ),
    TUFF(
        title = "Tuff-N-Uff",
        acronym = "TUFF",
        sports = listOf(Sport.MMA),
        tags = listOf("#TuffNUff", "@tuffnuff"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/265-tuff-n-uff",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    UAEW(
        title = "UAE Warriors",
        acronym = "UAEW",
        sports = listOf(Sport.MMA),
        tags = listOf("#UAEWarriors", "@uae_warriors"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/395-world-freefight-challenge-wfc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    UBP_BOXING(
        title = "Universum Box Promotion",
        acronym = "UBP",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3172-universum-box-promotion-ubp",
    ),
    UFC(
        title = "Ultimate Fighting Championship",
        acronym = "UFC",
        sports = listOf(Sport.MMA),
        tags = listOf("#DAZNfightclub", "#UFC", "@ufc"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1-ultimate-fighting-championship-ufc",
        mainCardBroadcaster = listOf(Broadcaster.DAZN, Broadcaster.UFC_FIGHT_PASS),
        prelimsBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
        earlyPrelimsBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    UNIFIED(
        title = "Unified Mixed Martial Arts",
        acronym = "UMMA",
        sports = listOf(Sport.MMA),
        tags = listOf("#unified", "@unifiedmma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/65-unified-mixed-martial-arts-umma",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    UWC_MX(
        title = "Ultimate Warrior Challenge Mexico",
        acronym = "UWCM",
        sports = listOf(Sport.MMA),
        tags = listOf("#UWC", "@uwc_mexico"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/504-uwc-mexico-uwcm",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    VENATOR(
        title = "Venator Fighting Championship",
        acronym = "Venator",
        sports = listOf(Sport.MMA),
        tags = listOf("#VenatorFC", "#VenatorFightNight", "#EuroMMA", "@VenatorFC"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1321-venator-fighting-championship-vfc",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    VENDETTA(
        title = "Vendetta Austria",
        acronym = "Vendetta",
        sports = listOf(Sport.MMA),
        tags = listOf("#Vendetta", "#EuroMMA"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/2140-iron-fist-gym-ifg",
    ),
    WB_BOXING(
        title = "Wasserman Boxing",
        acronym = "WB",
        sports = listOf(Sport.BOXING),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/4075-wasserman-boxing-wb",
    ),
    WLC_MM(
        title = "World Lethwei Championship",
        acronym = "WLC",
        sports = listOf(Sport.LETHWEI),
        tags = listOf("#WLC", "@WorldLethwei"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3322-world-lethwei-championship-wlc",
        active = false,
    ),
    WLMMA_DE(
        title = "We Love MMA",
        acronym = "WLMMA",
        sports = listOf(Sport.MMA),
        tags = listOf("#WLMMA", "#GerMMAny", "@wlmma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/643-free-fight-association-germany-ffa",
    ),
    WUG_WRESTLING(
        title = "Wrestling Underground",
        acronym = "WUG",
        sports = listOf(Sport.WRESTLING),
        tags = listOf("#WUG", "@WUG_Wrestling"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/3630-wrestling-underground-wug",
        mainCardBroadcaster = listOf(Broadcaster.UFC_FIGHT_PASS),
    ),
    WXC(
        title = "Warrior Xtreme Cagefighting",
        acronym = "WXC",
        sports = listOf(Sport.MMA),
        tags = listOf("#WXC", "@wxcmma"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/1022-warrior-xtreme-cagefighting-wxc",
    ),
    XFN_US(
        title = "Xtreme Fight Night",
        acronym = "XFN",
        sports = listOf(Sport.KICKBOXING),
        tags = listOf("@XFNfighting"),
        tapologyUrl = "https://www.tapology.com/fightcenter/promotions/324-xtreme-fighting-league-oklahoma-xfl",
    );

    fun exportName(): String {
        return when (this) {
            _OTHER -> "_other"
            else -> name.lowercase().replace('_', '-')
        }
    }

    fun dataFileName(): String {
        return "${exportName()}.json"
    }

    fun icalFileName(): String {
        return "${exportName()}.ics"
    }

    fun rssFileName(): String {
        return "${exportName()}.rss"
    }

    companion object {
        fun fromDataFileName(name: String): Promotion? {
            return values().find {
                it.dataFileName() == name
            }
        }

        fun parse(value: String): Promotion? {
            return values().find {
                it.name.equals(value, true) || it.title.equals(value, true)
            }
        }
    }
}