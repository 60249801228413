package de.kampfkalender.web.components

import de.kampfkalender.web.ApplicationContext
import emotion.react.css
import js.core.jso
import mui.icons.material.ChevronRight
import mui.icons.material.SvgIconComponent
import mui.material.ListItemButton
import mui.material.ListItemIcon
import mui.material.ListItemText
import react.FC
import react.Props
import react.create
import react.router.dom.NavLink
import react.router.useLocation
import react.useContext
import remix.run.router.To
import web.cssom.Color
import web.cssom.None
import web.cssom.em

external interface SidebarLinkProps : Props {
    var target: To?
    var onClick: (() -> Unit)?
    var title: String
    var icon: SvgIconComponent?
}

val SidebarLink = FC<SidebarLinkProps> { props ->
    val app = useContext(ApplicationContext)!!
    val currentPath = useLocation().pathname
    val icon = props.icon ?: ChevronRight

    NavLink {
        to = props.target ?: "#"
        onClick = {
            if (props.onClick != null) {
                it.preventDefault()
                props.onClick!!()
            }
            app.setSidebarOpened(false)
        }

        css {
            textDecoration = None.none
            color = Color.currentcolor
        }

        ListItemButton {
            selected = currentPath == props.target

            ListItemIcon {
                +icon.create()
            }
            ListItemText {
                sx = jso {
                    paddingRight = 1.em
                }
                +props.title
            }
        }
    }
}