package de.kampfkalender.common

import kotlinx.serialization.Serializable

@Serializable
data class ExportSummary(
    val lastUpdate: DateTime,
    val dataStart: String? = null,
    val dataEnd: String? = null,
    val dataMissing: List<String> = listOf(),
)