package de.kampfkalender.web.pages

import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.components.EventDialog
import de.kampfkalender.web.components.FilterSettings
import de.kampfkalender.web.components.ScheduleMonth
import de.kampfkalender.web.isServiceWorkerRegistered
import de.kampfkalender.web.utils.FILTER_ALL
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.addMonths
import de.kampfkalender.web.utils.dateFromYearMonth
import de.kampfkalender.web.utils.dateFromYearMonthDay
import de.kampfkalender.web.utils.formatAsIsoDate
import de.kampfkalender.web.utils.formatAsYearMonth
import de.kampfkalender.web.utils.getMaxDate
import de.kampfkalender.web.utils.getMinDate
import de.kampfkalender.web.utils.getMissingDates
import de.kampfkalender.web.utils.isSameDay
import de.kampfkalender.web.utils.toDate
import de.kampfkalender.web.utils.zeroPrefix
import js.core.jso
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.Box
import mui.material.CircularProgress
import mui.material.Divider
import mui.material.Size
import mui.material.TextField
import mui.material.useMediaQuery
import org.w3c.dom.events.Event
import react.FC
import react.Props
import react.ReactNode
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.TextAlign
import web.cssom.em
import web.cssom.number
import web.cssom.rem
import web.html.InputType
import kotlin.collections.getOrElse
import kotlin.collections.plus
import kotlin.js.Date
import kotlin.js.Promise

private var EVENTS: List<ExportedEvent> = listOf()

private fun isPageEndVisible(tolerance: Int = 50): Boolean {
    // println("pageYOffset  : ${window.pageYOffset}")
    // println("scrollTop    : ${document.documentElement!!.scrollTop}")
    // println("scrollHeight : ${document.documentElement!!.scrollHeight}")
    // println("outerHeight  : ${window.outerHeight}")
    // println("innerHeight  : ${window.innerHeight}")

    return try {
        val viewportHeight = window.innerHeight
        val scrollPosition = document.documentElement!!.scrollTop
        val totalHeight = document.documentElement!!.scrollHeight
        val diff = totalHeight - (viewportHeight + scrollPosition)
        diff <= tolerance
    } catch (_: Throwable) {
        false
    }
}

private fun isMissing(date: Date, missing: List<Date>): Boolean {
    return isMissing(date.getUTCFullYear(), date.getUTCMonth() + 1, missing)
}

private fun isMissing(year: Int, month: Int, missing: List<Date>): Boolean {
    return missing.any {
        year == it.getUTCFullYear() && month == it.getUTCMonth() + 1
    }
}

private fun getNextAvailableMonth(currentDate: Date, missing: List<Date>, min: Date, max: Date): Date? {
    if (currentDate.getTime() > max.getTime() || currentDate.getTime() < min.getTime()) {
        return null
    }

    var date = currentDate
    while (true) {
        date = date.addMonths(1)
        if (date.getTime() > max.getTime()) {
            return null
        }
        if (!isMissing(date, missing)) {
            return date
        }
    }
}

private fun loadEvents(date: Date, version: String): Promise<List<ExportedEvent>> {
    return loadEvents(date.getUTCFullYear(), date.getUTCMonth() + 1, version)
}

private fun loadEvents(year: Int, month: Int, version: String): Promise<List<ExportedEvent>> {
    // In case caching is managed by service worker, we don't need to append a version number.
    val url = if (isServiceWorkerRegistered()) {
        "/data/${year}/${month.zeroPrefix()}.json"
    } else {
        "/data/${year}/${month.zeroPrefix()}.json?v=${version}"
    }

    return window
        .fetch(url)
        .then {
            it.text()
        }
        .then {
            Json.decodeFromString<List<ExportedEvent>>(it)
        }
        .catch {
            listOf()
        }
}

private fun normalizeEvents(events: List<ExportedEvent>): List<ExportedEvent> {
    // Just return events in sorted order.
    return events.sorted()

    // Date overflow handling.
    /*return buildList {
        events.forEach { event ->
            val untilInclusive = event.until.addMilliseconds(-1)

            //val isPfl = event.id=="2022-06-pfl-regular-season-6"
            //if (isPfl) {
            //    console.log("${event.title}")
            //    console.log("FROM  : ${event.time.iso}")
            //    console.log("UNTIL : ${event.until.iso}")
            //}

            if (event.time.toDate().isSameDay(untilInclusive)) {
                this@buildList.add(event)
                return@forEach
            }

            //console.log("DATE OVERFLOW FOR ${event.title}")
            //console.log("FROM  : ${event.time.iso}")
            //console.log("UNTIL : ${event.until.iso}")

            val startDay = event.time.startOfDay()
            this@buildList.add(
                event.copy(
                    time = event.time,
                    until = startDay.addDays(1).toDateTime()
                )
            )
            //if (isPfl) {
            //    console.log("FIRST ENTRY");
            //    console.log("day   : ${startDay}")
            //    console.log("start : ${event.time}")
            //    console.log("until : ${startDay.addDays(1).toDateTime()}")
            //}

            var i = 0
            while (true) {
                val nextDay = startDay.addDays(++i)
                val reachedEnd = nextDay.isSameDay(untilInclusive)
                this@buildList.add(
                    event.copy(
                        time = nextDay.toDateTime(),
                        until = if (reachedEnd) event.until else nextDay.addDays(1).toDateTime()
                    )
                )
                //if (isPfl) {
                //    console.log("SECOND ENTRY");
                //    console.log("day   : ${nextDay}")
                //    console.log("start : ${nextDay.toDateTime()}")
                //    console.log("until : ${if (reachedEnd) event.until else nextDay.addDays(1).toDateTime()}")
                //}
                if (reachedEnd) {
                    break
                }
            }
        }

        sorted()
    }*/
}

val IndexPage = FC<Props> { _ ->
    // console.log("RENDER INDEX")
    val app = useContext(ApplicationContext)!!
    val minDate = app.summary?.getMinDate()
    val maxDate = app.summary?.getMaxDate()
    if (app.summary == null || minDate == null || maxDate == null) {
        Alert {
            sx = jso {
                marginTop = 6.rem
            }
            severity = AlertColor.warning
            AlertTitle {
                +"Schlechte Nachrichten..."
            }
            +"Es liegen gerade leider keine Termine vor. Bitte schaue später noch mal vorbei."
        }
        return@FC
    }
    val version = (app.summary.lastUpdate.toDate().getTime() / 1000).toString()
    val missingDates = app.summary.getMissingDates()

    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val (loading, setLoading) = useState(true)
    val (scrollToBottom, setScrollToBottom) = useState(false)
    val (selectedDate, setSelectedDate) = useState(Date())
    val (selectedFilter, setSelectedFilter) = useState(FILTER_ALL)
    val (loadedMonth, setLoadedMonth) = useState<Date?>(null)

    val (events, setEvents) = useState(listOf<ExportedEvent>())
    val (shownEvent, setShownEvent) = useState<ExportedEvent?>(null)

    val filterEvents: () -> Unit = {
        setEvents(EVENTS
            .filter { e: ExportedEvent ->
                val time = e.time.toDate()
                if (time.getTime() < selectedDate.getTime() && !time.isSameDay(selectedDate)) {
                    return@filter false
                }
                if (selectedFilter.free && !e.isFree()) {
                    return@filter false
                }
                if (selectedFilter.promotions.isNotEmpty()) {
                    if (!selectedFilter.promotions.contains(e.promotion)) {
                        return@filter false
                    }
                }
                if (selectedFilter.sports.isNotEmpty()) {
                    if (!selectedFilter.sports.any { e.sports.contains(it) }) {
                        return@filter false
                    }
                }
                if (selectedFilter.broadcasters.isNotEmpty()) {
                    if (!selectedFilter.broadcasters.any { e.broadcasters.contains(it) }) {
                        return@filter false
                    }
                }
                return@filter true
            }
        )
    }

    val loadNextMonth: () -> Unit = loadNextMonth@{
        //println("LOAD NEXT MONTH")
        setLoading(true)

        val month = getNextAvailableMonth(loadedMonth!!, missingDates, minDate, maxDate)
        if (month == null) {
            setLoading(false)
            setLoadedMonth(null)
            return@loadNextMonth
        }
        setLoadedMonth(month)
        loadEvents(month, version)
            .then { events ->
                EVENTS = EVENTS
                    .plus(normalizeEvents(events))
                    .distinctBy { it.id }

                filterEvents()
                setLoading(false)
            }
        //println(EVENTS)
    }

    useEffectOnce {
        window.scrollTo(0.0, 0.0)
    }

    // Current date was changed.
    // Reload all events starting from the selected month.
    useEffect(selectedDate) {
        setLoading(true)
        setLoadedMonth(null)
        setEvents(listOf())

        val month = if (!isMissing(selectedDate, missingDates)) {
            selectedDate
        } else {
            getNextAvailableMonth(selectedDate, missingDates, minDate, maxDate)
        }
        if (month == null) {
            setLoading(false)
            setLoadedMonth(null)
            return@useEffect
        }

        setLoadedMonth(month)
        loadEvents(month, version)
            .then { events ->
                EVENTS = normalizeEvents(events)

                setLoading(false)
                filterEvents()
            }
    }

    // Current filter was changed.
    // Apply filter on currently loaded events.
    useEffect(selectedFilter) {
        filterEvents()
    }

    // Load next event month, if the end of the page is visible.
    useEffect(events) {
        if (!loading && loadedMonth != null && isPageEndVisible()) {
            //println("PAGE BOTTOM STILL VISIBLE, LOAD NEXT MONTH")
            loadNextMonth()
        }
    }

    // Load next event month, if scrolled to the end of the page.
    useEffect {
        //println("INSTALL SCROLL LISTENER")
        val onScroll: (event: Event) -> Unit = onScroll@{
            if (!loading && loadedMonth != null && isPageEndVisible()) {
                // println("PAGE BOTTOM REACHED, LOAD NEXT MONTH")
                setScrollToBottom(true)
                loadNextMonth()
            }
        }
        window.addEventListener("scroll", onScroll)

        cleanup {
            //println("UNINSTALL SCROLL LISTENER")
            window.removeEventListener("scroll", onScroll)
        }
    }

    // Always scroll to page bottom, if page is loading.
    useEffect(loading, scrollToBottom) {
        if (loading && scrollToBottom) {
            window.scrollTo(0.0, document.documentElement?.scrollHeight?.toDouble() ?: 0.0)
            setScrollToBottom(false)
        }
    }

    Box {
        sx = jso {
            display = Display.flex
            paddingTop = 1.em
            flexDirection = if (isSmall) {
                FlexDirection.column
            } else {
                FlexDirection.row
            }
        }

        //Tooltip {
        //    title = ReactNode("Ab diesem Datum werden dir alle Events der folgenden 28 Tage angezeigt.")
        TextField {
            sx = jso {
                if (isSmall) {
                    marginBottom = 1.em
                } else {
                    marginRight = 0.5.em
                    flexGrow = number(0.0)
                }
            }

            size = if (isSmall) {
                Size.small
            } else {
                Size.medium
            }
            // fullWidth = true
            required = true
            type = InputType.date
            defaultValue = selectedDate.formatAsIsoDate()
            onInput = {
                val value = it.asDynamic().target.value as String
                //console.log("SELECTED DATE CHANGED TO: ${value}")
                //val date = parseISO(value)
                val date = dateFromYearMonthDay(value)
                setSelectedDate(date)
            }
            label = ReactNode("Events ab")
            InputLabelProps = jso {
                required = false
            }

            inputProps = jso {
                with(this.asDynamic()) {
                    this.min = minDate.formatAsIsoDate()
                    this.max = maxDate.formatAsIsoDate()
                }
            }
        }
        //}

        FilterSettings {
            filter = selectedFilter
            setFilter = { setSelectedFilter(it) }
        }
    }

    Divider {
        sx = jso {
            marginTop = 1.em
            marginBottom = 1.em
        }
    }

    if (events.isNotEmpty()) {
        EventDialog {
            open = shownEvent != null
            event = shownEvent
            onClose = { setShownEvent(null) }
        }
    }

    buildMap {
        events.forEach {
            val key = it.time.toDate().formatAsYearMonth()
            this@buildMap.put(
                key,
                this@buildMap.getOrElse(key) { listOf<ExportedEvent>() }.plus(it)
            )
        }
    }.forEach {
        ScheduleMonth {
            this.key = it.key
            this.month = dateFromYearMonth(it.key)
            this.events = it.value
            this.onShowEvent = { e -> setShownEvent(e) }
        }
    }

    if (loading) {
        Box {
            sx = jso {
                textAlign = TextAlign.center
                marginTop = 2.em
                marginBottom = 2.em
            }
            CircularProgress {
                disableShrink = true
            }
        }
    }

    if (!loading && events.isEmpty() && loadedMonth == null) {
        Alert {
            severity = AlertColor.warning
            AlertTitle {
                +"Schlechte Nachrichten..."
            }
            +"Es wurden keine passenden Events gefunden. Wähle einen anderen Zeitraum oder prüfe mal die Filterkriterien."
        }
    }
}
