package de.kampfkalender.web.utils

import de.kampfkalender.common.DateTime
import kotlin.js.Date

private val DATE_LOCALES = arrayOf("de-DE", "de")

/**
 * Returns a Date from a YYYY-MM formatted string.
 */
internal fun dateFromYearMonth(value: String, utc: Boolean = false): Date {
    val digits = value.split("-")
    return if (utc) {
        Date(Date.UTC(year = digits[0].toInt(), month = digits[1].toInt() - 1))
    } else {
        Date(year = digits[0].toInt(), month = digits[1].toInt() - 1)
    }
}

/**
 * Returns a Date from a YYYY-MM-DD formatted string.
 */
internal fun dateFromYearMonthDay(value: String, utc: Boolean = false): Date {
    val digits = value.split("-")
    return if (utc) {
        Date(Date.UTC(year = digits[0].toInt(), month = digits[1].toInt() - 1, day = digits[2].toInt()))
    } else {
        Date(year = digits[0].toInt(), month = digits[1].toInt() - 1, day = digits[2].toInt())
    }
}

/**
 * Returns the currently used timezone.
 */
internal fun getCurrentTimeZone(): String {
    return js("Intl.DateTimeFormat().resolvedOptions().timeZone") as String
}

/**
 * Returns a formatted Date in YYYY-MM-DD format.
 */
internal fun DateTime.formatAsIsoDate(): String {
    return this.toDate().formatAsIsoDate()
}

/**
 * Returns a formatted Date in YYYY-MM-DD format.
 */
internal fun Date.formatAsIsoDate(): String {
    return "${this.getFullYear()}-${(this.getMonth() + 1).zeroPrefix()}-${this.getDate().zeroPrefix()}"
}

/**
 * Returns a formatted Date in YYYY-MM format.
 */
internal fun DateTime.formatAsYearMonth(): String {
    return this.toDate().formatAsYearMonth()
}

/**
 * Returns a formatted Date in YYYY-MM format.
 */
internal fun Date.formatAsYearMonth(): String {
    return "${this.getFullYear()}-${(this.getMonth() + 1).zeroPrefix()}"
}

/**
 * Returns a formatted Date in HH:MM format.
 */
internal fun DateTime.formatAsTime(shortHour: Boolean = true): String {
    return this.toDate().formatAsTime(shortHour)
}

/**
 * Returns a formatted Date in HH:MM format.
 */
internal fun Date.formatAsTime(shortHour: Boolean = true): String {
    return if (shortHour) {
        "${this.getHours()}:${this.getMinutes().zeroPrefix()}"
    } else {
        "${this.getHours().zeroPrefix()}:${this.getMinutes().zeroPrefix()}"
    }
}

/**
 * Returns a formatted Date in DD.MM.YYY, HH:MM format.
 */
internal fun DateTime.formatAsDateTime(shortHourAndDate: Boolean = true): String {
    return this.toDate().formatAsTime(shortHourAndDate)
}

/**
 * Returns a formatted Date in DD.MM.YYY, HH:MM format.
 */
internal fun Date.formatAsDateTime(shortHourAndDate: Boolean = true): String {
    val time = this.formatAsTime(shortHourAndDate)
    val date = if (shortHourAndDate) {
        "${this.getDate()}.${this.getMonth() + 1}.${this.getFullYear()}"
    } else {
        "${this.getDate().zeroPrefix()}.${(this.getMonth() + 1).zeroPrefix()}.${this.getFullYear()}"
    }

    return "${date}, $time"
}

/**
 * Returns the weekday for a Date.
 */
internal fun DateTime.formatAsWeekday(short: Boolean = true): String {
    return this.toDate().formatAsWeekday(short)
}

/**
 * Returns the weekday for a Date.
 */
internal fun Date.formatAsWeekday(short: Boolean = true): String {
    return this.toLocaleDateString(DATE_LOCALES, dateLocaleOptions {
        weekday = if (short) "short" else "long"
    })
}

/**
 * Returns the month for a Date.
 */
internal fun DateTime.formatAsMonth(short: Boolean = true): String {
    return this.toDate().formatAsMonth(short)
}

/**
 * Returns the month for a Date.
 */
internal fun Date.formatAsMonth(short: Boolean = true): String {
    return this.toLocaleDateString(DATE_LOCALES, dateLocaleOptions {
        month = if (short) "short" else "long"
    })
}

/**
 * Returns a nicely formatted Date.
 */
internal fun DateTime.formatAsDate(): String {
    return this.toDate().formatAsDate()
}

/**
 * Returns a nicely formatted Date.
 */
internal fun Date.formatAsDate(): String {
    return "${this.formatAsWeekday()}, ${this.getDate()}. ${this.formatAsMonth()} ${this.getFullYear()}"
}

/**
 * Returns true, if this DateTime and another DateTime are on the same day.
 */
internal fun DateTime.isSameDay(otherDate: DateTime, utc: Boolean = false): Boolean {
    return this.toDate().isSameDay(otherDate.toDate(), utc)
}

/**
 * Returns true, if this Date and another Date are on the same day.
 */
internal fun Date.isSameDay(otherDate: Date, utc: Boolean = false): Boolean {
    return if (utc) {
        this.getUTCFullYear() == otherDate.getUTCFullYear() &&
                this.getUTCMonth() == otherDate.getUTCMonth() &&
                this.getUTCDate() == otherDate.getUTCDate()
    } else {
        this.getFullYear() == otherDate.getFullYear() &&
                this.getMonth() == otherDate.getMonth() &&
                this.getDate() == otherDate.getDate()
    }
}

/**
 * Returns true, if this DateTime and another DateTime are on the same month.
 */
internal fun DateTime.isSameMonth(otherDate: DateTime, utc: Boolean = false): Boolean {
    return this.toDate().isSameMonth(otherDate.toDate(), utc)
}

/**
 * Returns true, if this Date and another Date are on the same month.
 */
internal fun Date.isSameMonth(otherDate: Date, utc: Boolean = false): Boolean {
    return if (utc) {
        this.getUTCFullYear() == otherDate.getUTCFullYear() &&
                this.getUTCMonth() == otherDate.getUTCMonth()
    } else {
        this.getFullYear() == otherDate.getFullYear() &&
                this.getMonth() == otherDate.getMonth()
    }
}

/**
 * Returns the start of day for a DateTime.
 */
internal fun DateTime.startOfDay(utc: Boolean = false): Date {
    return this.toDate().startOfDay(utc)
}

/**
 * Returns the start of day for a Date.
 */
internal fun Date.startOfDay(utc: Boolean = false): Date {
    return if (utc) {
        Date(Date.UTC(year = this.getUTCFullYear(), month = this.getUTCMonth(), day = this.getUTCDate()))
    } else {
        Date(
            year = this.getFullYear(),
            month = this.getMonth(),
            day = this.getDate(),
            hour = 0,
            minute = 0,
            second = 0,
            millisecond = 0
        )
    }
}

/**
 * Returns the end of day for a DateTime.
 */
internal fun DateTime.endOfDay(utc: Boolean = false): Date {
    return this.toDate().endOfDay(utc)
}

/**
 * Returns the end of day for a Date.
 */
internal fun Date.endOfDay(utc: Boolean = false): Date {
    return Date(milliseconds = this.startOfDay(utc).getTime() + 86399999)
}

/**
 * Adds a certain amount of milliseconds to a DateTime.
 */
internal fun DateTime.addMilliseconds(amount: Int): Date {
    return this.toDate().addMilliseconds(amount)
}

/**
 * Adds a certain amount of milliseconds to a Date.
 */
internal fun Date.addMilliseconds(amount: Int): Date {
    return if (amount != 0) {
        Date(milliseconds = this.getTime() + amount)
    } else {
        this
    }
}

/**
 * Adds a certain amount of days to a DateTime.
 */
internal fun DateTime.addDays(amount: Int): Date {
    return this.toDate().addDays(amount)
}

/**
 * Adds a certain amount of days to a Date.
 */
internal fun Date.addDays(amount: Int): Date {
    return if (amount != 0) {
        return this.addMilliseconds(amount * 86400000)
    } else {
        this
    }
}

/**
 * Adds a certain amount of months to a DateTime.
 */
internal fun DateTime.addMonths(amount: Int): Date {
    return this.toDate().addMonths(amount)
}

/**
 * Adds a certain amount of months to a Date.
 * based on https://github.com/date-fns/date-fns/blob/master/src/addMonths/index.ts
 */
internal fun Date.addMonths(amount: Int): Date {
    if (amount == 0) {
        return this
    }

    val dayOfMonth = this.getDate()

    // The JS Date object supports date math by accepting out-of-bounds values for
    // month, day, etc. For example, new Date(2020, 0, 0) returns 31 Dec 2019 and
    // new Date(2020, 13, 1) returns 1 Feb 2021.  This is *almost* the behavior we
    // want except that dates will wrap around the end of a month, meaning that
    // new Date(2020, 13, 31) will return 3 Mar 2021 not 28 Feb 2021 as desired. So
    // we'll default to the end of the desired month by adding 1 to the desired
    // month and using a date of 0 to back up one day to the end of the desired
    // month.
    val endOfDesiredMonth = Date(
        year = this.getFullYear(),
        month = this.getMonth() + amount + 1,
        day = 0,
        hour = this.getHours(),
        minute = this.getMinutes(),
        second = this.getSeconds(),
        millisecond = this.getMilliseconds()
    )

    val daysInMonth = endOfDesiredMonth.getDate()

    // If we're already at the end of the month, then this is the correct date,
    // and we're done.
    if (dayOfMonth >= daysInMonth) {
        return endOfDesiredMonth
    }

    // Otherwise, we now know that setting the original day-of-month value won't
    // cause an overflow, so set the desired day-of-month. Note that we can't
    // just set the date of `endOfDesiredMonth` because that object may have had
    // its time changed in the unusual case where a DST transition was on
    // the last day of the month and its local time was in the hour skipped or
    // repeated next to a DST transition.  So we use `date` instead which is
    // guaranteed to still have the original time.
    return Date(
        year = endOfDesiredMonth.getFullYear(),
        month = endOfDesiredMonth.getMonth(),
        day = dayOfMonth,
        hour = this.getHours(),
        minute = this.getMinutes(),
        second = this.getSeconds(),
        millisecond = this.getMilliseconds()
    )
}

/**
 * Converts a DateTime to a Date.
 */
internal fun DateTime.toDate(): Date {
    return Date(dateString = this.iso)
}

/**
 * Converts a Date to a DateTime.
 */
internal fun Date.toDateTime(): DateTime {
    return DateTime(
        "${this.getUTCFullYear()}-" +
                "${(this.getUTCMonth() + 1).zeroPrefix()}-" +
                "${this.getUTCDate().zeroPrefix()}T" +
                "${this.getUTCHours().zeroPrefix()}:" +
                "${this.getUTCMinutes().zeroPrefix()}Z"
    )
}
