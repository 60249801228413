package de.kampfkalender.web.components

import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.utils.FULL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.formatAsMonth
import de.kampfkalender.web.utils.formatAsWeekday
import js.core.jso
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useContext
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FlexWrap
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.None
import web.cssom.TextAlign
import web.cssom.number
import web.cssom.px
import web.cssom.rem
import web.cssom.rgb
import kotlin.js.Date

external interface ScheduleDayProps : Props {
    var day: Date
    var events: List<ExportedEvent>
    var onShowEvent: (event: ExportedEvent) -> Unit
}

val ScheduleDay = FC<ScheduleDayProps> { props ->
    val app = useContext(ApplicationContext)!!
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val isFull = useMediaQuery(FULL_SIZE_MEDIA_QUERY)

    val calendarColor = if (app.darkMode)
        rgb(255, 255, 255, 0.5)
    else
        rgb(0, 0, 0, 0.5)

    Box {
        component = ReactHTML.div
        sx = jso {
            display = Display.flex

            if (isSmall) {
                flexDirection = FlexDirection.column
                marginBottom = 0.5.rem
                marginTop = 0.5.rem
            } else {
                flexDirection = FlexDirection.row
            }
        }

        Box {
            sx = jso {
                display = Display.flex
                textAlign = TextAlign.center
                userSelect = None.none

                if (isSmall) {
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.baseline
                    flexDirection = FlexDirection.row
                    marginBottom = 0.5.rem
                } else {
                    flexGrow = number(0.0)
                    flexShrink = number(0.0)
                    flexDirection = FlexDirection.column
                    width = 4.rem
                    marginRight = 1.5.rem
                }
            }

            Typography {
                sx = jso {
                    color = calendarColor
                    fontWeight = FontWeight.bolder
                    if (isSmall) {
                        fontSize = 2.rem
                        //letterSpacing = 1.px
                    } else {
                        fontSize = 2.3.rem
                        letterSpacing = 3.px
                    }
                }
                component = ReactHTML.h2
                variant = TypographyVariant.h4

                +props.day.formatAsWeekday()
            }
            Typography {
                sx = jso {
                    color = calendarColor
                    if (isSmall) {
                        fontSize = 2.rem
                        fontWeight = FontWeight.lighter
                        marginLeft = 0.5.rem
                        marginRight = 0.5.rem
                    }
                }
                if (isSmall) {
                    component = ReactHTML.h2
                    variant = TypographyVariant.h4
                }

                +"${props.day.getDate()} ${props.day.formatAsMonth()}"
            }
            Typography {
                sx = jso {
                    color = calendarColor
                    if (isSmall) {
                        fontSize = 2.rem
                        fontWeight = FontWeight.lighter
                    }
                }
                if (isSmall) {
                    component = ReactHTML.h2
                    variant = TypographyVariant.h4
                }

                +props.day.getFullYear().toString()
            }
        }

        Box {
            sx = jso {
                flexGrow = number(1.0)

                if (isFull) {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    flexWrap = FlexWrap.wrap
                    marginLeft = (-1).rem
                }
            }

            props.events.forEach {
                ScheduleEntry {
                    key = it.id
                    event = it
                    onShowEvent = props.onShowEvent
                }
            }
        }
    }
}
