package de.kampfkalender.web.utils

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.ThemeOptions
import web.cssom.px

const val SMALL_SIZE_MEDIA_QUERY = "(max-width:600px)"
const val FULL_SIZE_MEDIA_QUERY = "(min-width:900px)"
const val PREFER_DARK_MEDIA_QUERY = "(prefers-color-scheme:dark)"

val TYPOGRAPHY_OPTIONS = jso<dynamic> {
    h1 = jso {
        //fontSize = "3.0rem"
        fontSize = "clamp(1.75rem, 5vw, 3rem)"
    }
    h2 = jso {
        //fontSize = "2.75rem"
        fontSize = "clamp(1.5rem, 4.6vw, 2.75rem)"
    }
    h3 = jso {
        //fontSize = "2.25rem"
        fontSize = "clamp(1.3rem, 4.25vw, 2.25rem)"
    }
    h4 = jso {
        //fontSize = "1.8rem"
        fontSize = "clamp(1.1rem, 3.75vw, 2rem)"
    }
}

val DARK_THEME_OPTIONS = jso<ThemeOptions> {
    palette = jso {
        mode = PaletteMode.dark
        // primary = jso {
        //     main = "#90caf9"
        // }
        // secondary = jso {
        //     main = "#f48fb1"
        // }
        // background = jso {
        //     default = "#212121"
        //     paper = "#424242"
        // }
    }
    typography = TYPOGRAPHY_OPTIONS
}

val LIGHT_THEME_OPTIONS = jso<ThemeOptions> {
    palette = jso {
        mode = PaletteMode.light
        background = jso {
            default = "#e7ebf0"
        }
    }
    typography = TYPOGRAPHY_OPTIONS
}

object Styles {
    object Header {
        val Height = 64.px
    }

    //object Sidebar {
    //    val Width = 135.px
    //}
}