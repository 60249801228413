package de.kampfkalender.common

import de.kampfkalender.common.data.BroadcastInfo
import kotlinx.serialization.Serializable

@Serializable
data class ExportedEvent(
    val id: String,
    val title: String,
    val time: DateTime,
    val until: DateTime,
    val live: Boolean = true,
    val promo: Boolean = false,
    val promotion: Promotion,
    val sports: List<Sport> = listOf(),
    val links: List<String> = listOf(),
    val card: List<String> = listOf(),
    val broadcasters: Map<Broadcaster, BroadcastInfo> = mapOf(),
) : Comparable<ExportedEvent> {
    companion object {
        val DEFAULT_SORT = compareBy<ExportedEvent> { it.time }.thenBy { it.id }
    }

    override fun compareTo(other: ExportedEvent): Int {
        return DEFAULT_SORT.compare(this, other)
    }

    fun isFree(): Boolean {
        return this.broadcasters.any { it.value.free==true }
    }
}