package de.kampfkalender.web.components

import de.kampfkalender.common.ExportedEvent
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.utils.FULL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.formatAsTime
import js.core.jso
import mui.material.Card
import mui.material.CardContent
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.responsive
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useContext
import react.useState
import web.cssom.AlignItems
import web.cssom.Border
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.important
import web.cssom.minus
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.rem
import web.cssom.rgb

external interface ScheduleEntryProps : Props {
    var event: ExportedEvent
    var onShowEvent: (event: ExportedEvent) -> Unit
}

val ScheduleEntry = FC<ScheduleEntryProps> { props ->
    val app = useContext(ApplicationContext)!!
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val isFull = useMediaQuery(FULL_SIZE_MEDIA_QUERY)
    val (hover, setHover) = useState(false)

    val timeRange = props.event.time.formatAsTime() + " - " + props.event.until.formatAsTime()

    Card {
        sx = jso {
            marginBottom = 1.rem
            cursor = Cursor.pointer
            border = Border(1.px, LineStyle.solid)
            borderColor = if (app.darkMode) {
                if (hover) {
                    rgb(255, 255, 255)
                } else {
                    rgb(255, 255, 255, 0.0)
                }
            } else {
                if (hover) {
                    rgb(0, 0, 0)
                } else {
                    rgb(0, 0, 0, 0.0)
                }
            }

            if (isFull) {
                //minWidth = 50.pct - 1.rem
                maxWidth = 50.pct - 1.rem
                width = 100.pct
                flexGrow = number(1.0)
                flexShrink = number(0.0)
                marginLeft = 1.rem
            }
        }

        onClick = { props.onShowEvent(props.event) }

        // TODO: Find a better solution for hovering. Something based on CSS :hover would be nice.
        onMouseOver = { setHover(true) }
        onMouseOut = { setHover(false) }

        CardContent {
            sx = jso {
                height = 100.pct
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.spaceBetween
                paddingBottom = important(16.px)
            }

            // title left, time right
            Stack {
                sx = jso {
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = if (isSmall) AlignItems.center else AlignItems.start
                    marginBottom = 0.75.rem
                }

                direction = if (isSmall) {
                    responsive(StackDirection.columnReverse)
                } else {
                    responsive(StackDirection.row)
                }
                spacing = responsive(0.5.rem)

                // title left
                Typography {
                    sx = jso {
                        lineHeight = if (isSmall) number(1.3) else number(1.1)
                        fontSize = 1.1.rem
                        fontWeight = FontWeight.bold
                        if (isSmall) {
                            textAlign = TextAlign.center
                        }
                    }

                    component = ReactHTML.h2
                    variant = TypographyVariant.body1
                    gutterBottom = false

                    +props.event.title
                }

                // time right
                Typography {
                    sx = jso {
                        flexShrink = number(0.0)
                        lineHeight = number(1.1)
                        fontSize = if (isSmall) 0.9.rem else 1.1.rem
                    }

                    component = ReactHTML.time
                    variant = TypographyVariant.body1
                    gutterBottom = false

                    +timeRange
                }
            }

            EventTags {
                event = props.event
                withBroadcaster = true
                fromDialog = false
            }
        }
    }
}
