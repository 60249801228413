package de.kampfkalender.web.components

import de.kampfkalender.web.utils.FILTER_ALL
import de.kampfkalender.web.utils.FILTER_FREE
import de.kampfkalender.web.utils.Filter
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import js.core.jso
import mui.icons.material.AddCircle
import mui.icons.material.Delete
import mui.icons.material.Edit
import mui.icons.material.FilterAlt
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.ListItemIcon
import mui.material.Menu
import mui.material.MenuItem
import mui.material.Size
import mui.material.TextField
import mui.material.useMediaQuery
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.aria.AriaHasPopup
import react.dom.aria.ariaControls
import react.dom.aria.ariaExpanded
import react.dom.aria.ariaHasPopup
import react.dom.aria.ariaLabel
import react.dom.aria.ariaLabelledBy
import react.useState
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.em
import web.cssom.number
import web.dom.Element

external interface FilterSettingsProps : Props {
    var filter: Filter
    var setFilter: (filter: Filter) -> Unit
}

val FilterSettings = FC<FilterSettingsProps> { props ->
    val (menuAnchor, setMenuAnchor) = useState<Element?>(null)
    val (menuOpened, setMenuOpened) = useState(false)
    val (filters, setFilters) = useState(listOf(FILTER_ALL, FILTER_FREE).plus(Filter.readFromStorage()))
    val (filterDialogOpen, setFilterDialogOpen) = useState(false)
    val (filterDialogCreate, setFilterDialogCreate) = useState(false)
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)

    Box {
        sx = jso {
            display = Display.flex
            flexDirection = FlexDirection.row
            flexGrow = number(1.0)
        }

        //Tooltip {
        //    title = ReactNode("Es werden nur Events angezeigt, die diesem Kriterium entsprechen.")
        @Suppress("UPPER_BOUND_VIOLATED")
        Autocomplete<AutocompleteProps<Filter>> {
            sx = jso {
                flexGrow = number(1.0)
                marginRight = 0.5.em
            }
            size = if (isSmall) {
                //Size.small
                "small"
            } else {
                //Size.medium
                "medium"
            }

            value = props.filter
            options = filters.toTypedArray()
            multiple = false
            disableClearable = true
            selectOnFocus = false
            clearOnBlur = true
            handleHomeEndKeys = true
            disablePortal = true
            //noOptionsText = ReactNode("Keine passenden Filter gefunden.")
            renderInput = { params ->
                TextField.create {
                    // console.log(params)
                    params.inputProps.asDynamic().readOnly = true
                    +params
                    label = ReactNode("Events filtern")
                }
            }
            getOptionLabel = { it.title }
            onChange = { _, value: Filter, _, _ -> props.setFilter(value) }
        }
        //}

        //Tooltip {
        //    title = ReactNode("Bei Bedarf kannst du eigene Kriterien zur Darstellung der Events erstellen.")
        Button {
            sx = jso {
                flexGrow = number(0.0)
            }

            id = "event-search-button"
            variant = ButtonVariant.outlined
            size = if (isSmall) Size.small else Size.medium

            ariaExpanded = menuOpened
            ariaHasPopup = AriaHasPopup.`true`
            ariaControls = "event-search-menu"
            ariaLabel = "Events filtern"

            onClick = {
                setMenuAnchor(it.currentTarget)
                setMenuOpened(true)
            }

            FilterAlt()
        }
        //}
    }

    Menu {
        id = "event-search-menu"
        anchorEl = if (menuAnchor != null) {
            { menuAnchor }
        } else {
            null
        }
        open = menuOpened
        onClose = {
            setMenuOpened(false)
            // setMenuAnchor(null)
        }
        ariaLabelledBy = "event-search-button"
        anchorOrigin = jso {
            horizontal = "left"
            vertical = "bottom"
        }

        MenuItem {
            onClick = {
                setMenuOpened(false)
                setFilterDialogCreate(true)
                setFilterDialogOpen(true)
            }

            ListItemIcon {
                AddCircle()
            }

            +"Neuer Filter"
        }
        MenuItem {
            disabled = !props.filter.isCustom()
            onClick = {
                setMenuOpened(false)
                setFilterDialogCreate(false)
                setFilterDialogOpen(true)
            }

            ListItemIcon {
                Edit()
            }

            +"Filter bearbeiten"
        }
        MenuItem {
            disabled = !props.filter.isCustom()
            onClick = {
                setMenuOpened(false)
                val newFilters = Filter.readFromStorage()
                    .filter { it.title != props.filter.title }
                    .sorted()
                Filter.writeToStorage(newFilters)

                setFilters(
                    buildList {
                        add(FILTER_ALL)
                        add(FILTER_FREE)
                        addAll(newFilters)
                    }
                )
                props.setFilter(FILTER_ALL)
            }

            ListItemIcon {
                Delete()
            }

            +"Filter löschen"
        }
    }

    FilterDialog {
        create = filterDialogCreate
        open = filterDialogOpen
        filter = props.filter
        onClose = { setFilterDialogOpen(false) }
        onChange = { filter ->
            val newFilters = Filter.readFromStorage()
                .filter { filterDialogCreate || it.title != props.filter.title }
                .plus(filter)
                .sorted()
            Filter.writeToStorage(newFilters)

            setFilters(
                buildList {
                    add(FILTER_ALL)
                    add(FILTER_FREE)
                    addAll(newFilters)
                }
            )
            props.setFilter(filter)
        }
    }
}
