package de.kampfkalender.web.pages

import js.core.jso
import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.Promotion
import de.kampfkalender.common.Sport
import de.kampfkalender.web.utils.AUTHOR_FACEBOOK_URL
import de.kampfkalender.web.utils.AUTHOR_INSTAGRAM_URL
import de.kampfkalender.web.utils.AUTHOR_TWITTER_URL
import de.kampfkalender.web.utils.BASE_URL
import de.kampfkalender.web.utils.BROADCASTERS
import de.kampfkalender.web.utils.PROMOTIONS
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.SPORTS
import kotlinx.browser.window
import mui.base.BlurOnSelect
import mui.icons.material.ExpandMore
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Box
import mui.material.Divider
import mui.material.FormControl
import mui.material.FormControlMargin
import mui.material.FormControlVariant
import mui.material.InputLabel
import mui.material.Link
import mui.material.MenuItem
import mui.material.Select
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.material.TextFieldColor
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.responsive
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.createElement
import react.dom.aria.ariaControls
import react.dom.aria.ariaLabelledBy
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.q
import react.dom.html.ReactHTML.strong
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.Cursor
import web.cssom.important
import web.cssom.rem
import web.window.WindowTarget

private enum class AccordionView {
    SUBSCRIPTION,
    PRICING,
    FORMATS,
    ICAL_ANDROID,
    ICAL_IOS,
    ICAL_GOOGLE,
    ICAL_ICLOUD,
    ICAL_OTHER,
    HELP,
}

private enum class SubscriptionType(val title: String) {
    ALL(title = "alle Events"),
    FREE(title = "alle kostenlosen Events"),
    PODCAST(title = "alle Podcasts"),
    SPORT(title = "eine bestimmte Sportart"),
    PROMOTION(title = "ein bestimmter Veranstalter"),
    BROADCASTER(title = "ein bestimmter Sender"),
}

val SubscribePage = FC<Props> {
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val (accordionView, setAccordionView) = useState<AccordionView?>(AccordionView.SUBSCRIPTION)
    val (subscriptionType, setSubscriptionType) = useState<SubscriptionType?>(null)
    val (promotion, setPromotion) = useState<Promotion?>(null)
    val (sport, setSport) = useState<Sport?>(null)
    val (broadcaster, setBroadcaster) = useState<Broadcaster?>(null)
    val (icalUrl, setIcalUrl) = useState<String?>(null)
    val (rssUrl, setRssUrl) = useState<String?>(null)

    useEffectOnce {
        window.scrollTo(0.0, 0.0)
    }

    useEffect(subscriptionType, promotion, sport, broadcaster) {
        when (subscriptionType) {
            SubscriptionType.ALL -> {
                setIcalUrl("${BASE_URL}/ical/all.ics")
                setRssUrl("${BASE_URL}/rss/all.rss")
            }
            SubscriptionType.FREE -> {
                setIcalUrl("${BASE_URL}/ical/free.ics")
                setRssUrl("${BASE_URL}/rss/free.rss")
            }
            SubscriptionType.PODCAST -> {
                setIcalUrl("${BASE_URL}/ical/podcast.ics")
                setRssUrl("${BASE_URL}/rss/podcast.rss")
            }
            SubscriptionType.BROADCASTER -> {
                if (broadcaster != null) {
                    setIcalUrl("${BASE_URL}/ical/broadcaster/${broadcaster.icalFileName()}")
                    setRssUrl("${BASE_URL}/rss/broadcaster/${broadcaster.rssFileName()}")
                } else {
                    setIcalUrl(null)
                    setRssUrl(null)
                }
            }
            SubscriptionType.PROMOTION -> {
                if (promotion != null) {
                    setIcalUrl("${BASE_URL}/ical/promotion/${promotion.icalFileName()}")
                    setRssUrl("${BASE_URL}/rss/promotion/${promotion.rssFileName()}")
                } else {
                    setIcalUrl(null)
                    setRssUrl(null)
                }
            }
            SubscriptionType.SPORT -> {
                if (sport != null) {
                    setIcalUrl("${BASE_URL}/ical/sport/${sport.icalFileName()}")
                    setRssUrl("${BASE_URL}/rss/sport/${sport.rssFileName()}")
                } else {
                    setIcalUrl(null)
                    setRssUrl(null)
                }
            }
            else -> {
                setIcalUrl(null)
                setRssUrl(null)
            }
        }
    }

    Typography {
        sx = jso {
            paddingTop = 1.5.rem
        }

        component = ReactHTML.h1
        variant = TypographyVariant.h1
        +"Kampfkalender abonnieren"
    }

    Divider {
        sx = jso {
            marginTop = 0.5.rem
            marginBottom = 1.0.rem
        }
    }

    Typography {
        paragraph = true
        +"Man muss diese Webseite nicht regelmäßig besuchen, um auf dem neuesten Stand zu bleiben. "
        +"Die Termine kannst du z.B. auch "
        strong { +"in den Kalender deines Smartphones integrieren" }
        +". Hier erfährst du, welche Schritte dafür nötig sind. Es ist einfacher als du denkst. 😉"
    }

    // SUBSCRIPTION
    Accordion {
        expanded = accordionView == AccordionView.SUBSCRIPTION
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.SUBSCRIPTION else null) }

        AccordionSummary {
            id = "subscription-header"
            ariaControls = "subscription-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Welche Termine interessieren dich?"
            }
        }

        AccordionDetails {
            id = "subscription-content"
            ariaLabelledBy = "subscription-header"

            Typography {
                paragraph = true

                +"Bitte wähle aus, welche Events aus dem Kampfkalender du abonnieren möchtest."
            }

            FormControl {
                sx = jso {
                    marginBottom = 1.0.rem
                }

                fullWidth = true

                InputLabel {
                    id = "subscription-type-label"

                    +"Welche Termine interessieren dich?"
                }

                Select {
                    id = "subscription-type-field"
                    labelId = "subscription-type-label"
                    label = ReactNode("Welche Termine interessieren dich?")
                    @Suppress("UnsafeCastFromDynamic")
                    defaultValue = "".asDynamic()
                    onChange = { e: dynamic, _: dynamic ->
                        val value = e.target.value as String
                        //println("SELECTED ${value}")
                        setSubscriptionType(if (value.isBlank()) null else SubscriptionType.valueOf(value))
                    }

                    SubscriptionType.values().forEach {
                        MenuItem {
                            value = it.name
                            +it.title
                        }
                    }
                }
            }

            if (subscriptionType == SubscriptionType.SPORT) {
                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Sport>> {
                    value = sport
                    multiple = false
                    options = SPORTS.toTypedArray()
                    selectOnFocus = false
                    clearOnBlur = true
                    handleHomeEndKeys = true
                    disablePortal = true
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Sportarten gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Welche Sportart interessiert dich?")
                        }
                    }
                    getOptionLabel = { it.title }
                    onChange = { _, value: Sport, _, _ ->
                        setSport(value)
                    }
                }
            }

            if (subscriptionType == SubscriptionType.PROMOTION) {
                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Promotion>> {
                    sx = jso {
                        marginBottom = 1.0.rem
                    }

                    value = promotion
                    multiple = false
                    options = PROMOTIONS.toTypedArray()
                    selectOnFocus = false
                    clearOnBlur = true
                    handleHomeEndKeys = true
                    disablePortal = true
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Veranstalter gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Welcher Veranstalter interessiert dich?")
                        }
                    }
                    getOptionLabel = {
                        if (it != Promotion._OTHER && it.acronym != it.title) {
                            "${it.acronym}: ${it.title}"
                        } else {
                            it.title
                        }
                    }
                    onChange = { _, value: Promotion, _, _ ->
                        setPromotion(value)
                    }
                }
            }

            if (subscriptionType == SubscriptionType.BROADCASTER) {
                @Suppress("UPPER_BOUND_VIOLATED")
                Autocomplete<AutocompleteProps<Broadcaster>> {
                    value = broadcaster
                    multiple = false
                    options = BROADCASTERS.toTypedArray()
                    selectOnFocus = false
                    clearOnBlur = true
                    handleHomeEndKeys = true
                    disablePortal = true
                    blurOnSelect = BlurOnSelect.`true`
                    noOptionsText = ReactNode("Keine passenden Sender gefunden.")
                    renderInput = { params ->
                        TextField.create {
                            +params
                            label = ReactNode("Welcher Sender interessiert dich?")
                        }
                    }
                    getOptionLabel = { it.title }
                    onChange = { _, value: Broadcaster, _, _ ->
                        setBroadcaster(value)
                    }
                }
            }

            if (icalUrl != null || rssUrl != null) {
                Alert {
                    sx = jso {
                        marginTop = 1.rem
                        marginBottom = if (isSmall) 0.5.rem else 1.rem
                    }
                    severity = AlertColor.success

                    strong {
                        +"Es hat funktioniert!"
                    }
                    +" Du kannst nun eine der folgenden Adressen in deinen Kalender übernehmen, um die gewählten Termine zu abonnieren:"
                }

                Stack {
                    spacing = responsive(0.5.rem)
                    direction = responsive(if (isSmall) StackDirection.column else StackDirection.row)

                    if (icalUrl != null) {
                        TextField {
                            color = TextFieldColor.success
                            variant = FormControlVariant.filled
                            fullWidth = true
                            margin = if (isSmall) FormControlMargin.normal else FormControlMargin.none
                            label = ReactNode(
                                arrayOf(
                                    ReactNode("Kalender im "),
                                    createElement(
                                        strong,
                                        jso {},
                                        ReactNode("iCal-Format")
                                    )
                                )
                            )
                            value = icalUrl
                            inputProps = jso {
                                this.asDynamic().readOnly = true
                            }
                            onFocus = { e: dynamic ->
                                // console.log("ICAL FOCUS", e)
                                @Suppress("UnsafeCastFromDynamic")
                                e.target.select()
                            }
                        }
                    }

                    if (rssUrl != null) {
                        TextField {
                            color = TextFieldColor.success
                            variant = FormControlVariant.filled
                            fullWidth = true
                            margin = if (isSmall) FormControlMargin.normal else FormControlMargin.none
                            label = ReactNode(
                                arrayOf(
                                    ReactNode("Kalender im "),
                                    createElement(
                                        strong,
                                        jso {},
                                        ReactNode("RSS-Format")
                                    )
                                )
                            )
                            value = rssUrl
                            inputProps = jso {
                                this.asDynamic().readOnly = true
                            }
                            onFocus = { e: dynamic ->
                                // console.log("RSS FOCUS", e)
                                @Suppress("UnsafeCastFromDynamic")
                                e.target.select()
                            }
                        }
                    }
                }
            }
        }
    }

    // PRICING
    Accordion {
        expanded = accordionView == AccordionView.PRICING
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.PRICING else null) }

        AccordionSummary {
            id = "pricing-header"
            ariaControls = "pricing-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Was kostet die Nutzung des Kalenders?"
            }
        }

        AccordionDetails {
            id = "pricing-content"
            ariaLabelledBy = "pricing-header"

            Typography {
                paragraph = true

                +"Sowohl die Nutzung des Kalenders als auch das Abonnement der Termine ist "
                strong {
                    +"komplett und dauerhaft kostenfrei"
                }
                +(". Dies ist mein kleines Hobby-Projekt, mit dem ich euch Kampfsport-Fans das Leben etwas leichter " +
                        "machen will. Schließlich ist nichts ärgerlicher, als ein Event zu verpassen - weil man den " +
                        "Termin verschläft oder die Zeitzonen durcheinander gebracht hat.")
            }

            Typography {
                paragraph = true

                +("Auch eine kommerzielle Nutzung der Kalenderdaten ist erwünscht und kostenfrei möglich. Jedoch würde " +
                        "ich mich über einen Backlink auf ")
                strong { q { +"kampfkalender.de" } }
                +" sehr freuen."
            }
        }
    }

    // FORMATS
    Accordion {
        expanded = accordionView == AccordionView.FORMATS
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.FORMATS else null) }

        AccordionSummary {
            id = "formats-header"
            ariaControls = "formats-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Welches Format sollte ich verwenden?"
            }
        }

        AccordionDetails {
            id = "formats-content"
            ariaLabelledBy = "formats-header"

            Typography {
                paragraph = true

                +"Die Events aus dem Kalender können in zwei verschiedenen Formaten abgerufen werden:"
            }

            Typography {
                sx = jso { marginBottom = 1.5.rem }
                component = ReactHTML.ul

                li {
                    Typography {
                        paragraph = true

                        +"Das "
                        Link {
                            target = WindowTarget._blank
                            href = "https://de.wikipedia.org/wiki/ICalendar"
                            strong {
                                q {
                                    +"iCalendar"
                                }
                            }
                        }
                        +"-Format (kurz "
                        q { +"iCal" }
                        +(") ist in den allermeisten Fällen die richtige Wahl, wenn ihr Termine in euren Kalender " +
                                "übernehmen wollt. Nahezu alle Kalender-Programme können mit diesem Dateiformat umgehen.")
                    }
                }
                li {
                    Typography {
                        paragraph = true

                        +"Das "
                        Link {
                            target = WindowTarget._blank
                            href = "https://de.wikipedia.org/wiki/RSS_(Web-Feed)"
                            strong {
                                q {
                                    +"RSS"
                                }
                            }
                        }
                        +"-Format (auch bekannt als "
                        q { +"Web-Feed" }
                        +(") wird in der Regel verwendet um Nachrichten abzurufen. Ihr könnt dieses Format mit einem " +
                                "RSS-Reader oder vielen E-Mailprogrammen nutzen. Ebenso kann dieses Format besonders " +
                                "einfach in Webseiten eingebunden werden - z.B. um dort die aktuellsten Termine anzuzeigen.")
                    }
                }
            }
        }
    }

    // ICAL_ANDROID
    Accordion {
        expanded = accordionView == AccordionView.ICAL_ANDROID
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.ICAL_ANDROID else null) }

        AccordionSummary {
            id = "ical-android-header"
            ariaControls = "ical-android-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Abonnieren auf Android-Geräten"
            }
        }

        AccordionDetails {
            id = "ical-android-content"
            ariaLabelledBy = "ical-android-header"

            Typography {
                paragraph = true

                +("Unter Android könnt ihr entweder den Google Calendar nutzen oder ihr installiert eine separate App " +
                        "zur Synchronisation. Ich verwende dafür z.B. ")
                Link {
                    target = WindowTarget._blank
                    href = "https://play.google.com/store/apps/details?id=at.bitfire.icsdroid&hl=de"
                    +"ICSx⁵"
                }
                +". Im "
                Link {
                    target = WindowTarget._blank
                    href = "https://f-droid.org/en/packages/at.bitfire.icsdroid"
                    +"f-droid Store"
                }
                +" ist das Programm auch kostenlos zu haben. Ihr könnt in der App die "
                Link {
                    sx = jso { cursor = Cursor.pointer }
                    onClick = { setAccordionView(AccordionView.SUBSCRIPTION) }
                    strong { +"iCal-Adressen" }
                }
                +(" eurer Wahl hinterlegen. Das Programm ruft dann automatisch (z.B. einmal täglich) die Termine ab " +
                        "und aktualisiert euren Kalender entsprechend.")
            }
        }
    }

    // ICAL_IOS
    Accordion {
        expanded = accordionView == AccordionView.ICAL_IOS
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.ICAL_IOS else null) }

        AccordionSummary {
            id = "ical-ios-header"
            ariaControls = "ical-ios-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Abonnieren auf iOS-Geräten (iPhone / iPad)"
            }
        }

        AccordionDetails {
            id = "ical-ios-content"
            ariaLabelledBy = "ical-ios-header"

            Typography {
                paragraph = true

                +"Unter "
                strong { +"iOS (iPhone / iPad)" }
                +(" könnt ihr die Termine gemäß ")
                Link {
                    target = WindowTarget._blank
                    href = "https://support.apple.com/de-de/guide/iphone/iph3d1110d4/ios"
                    +"dieser Anleitung von Apple"
                }
                +" (Abschnitt "
                q { +"Kalender abonnieren" }
                +") importieren. Verwendet dabei die "
                Link {
                    sx = jso { cursor = Cursor.pointer }
                    onClick = { setAccordionView(AccordionView.SUBSCRIPTION) }
                    strong { +"iCal-Adressen" }
                }
                +" eurer Wahl."
            }
        }
    }

    // ICAL_GOOGLE
    Accordion {
        expanded = accordionView == AccordionView.ICAL_GOOGLE
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.ICAL_GOOGLE else null) }

        AccordionSummary {
            id = "ical-google-header"
            ariaControls = "ical-google-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Abonnieren via Google Calendar"
            }
        }

        AccordionDetails {
            id = "ical-google-content"
            ariaLabelledBy = "ical-google-header"

            Typography {
                paragraph = true

                +("Ihr könnt die Termine in euer Google-Konto übernehmen. Meldet euch dafür über den Web-Browser im " +
                        "Google Calender an und klickt auf der linken Seite neben ")
                strong { q { +"Weitere Kalender" } }
                +" auf das Plus-Symbol. In der folgenden Auswahl klickt ihr auf "
                strong { q { +"Per URL" } }
                +" und tragt in's dargestellte Eingabefeld die "
                Link {
                    sx = jso { cursor = Cursor.pointer }
                    onClick = { setAccordionView(AccordionView.SUBSCRIPTION) }
                    strong { +"iCal-Adressen" }
                }
                +" eurer Wahl ein."
            }

            Typography {
                paragraph = true

                +("Sobald die Termine in eurem Google Calendar vorhanden sind, solltet ihr auch über das " +
                        "Smartphone / Tablet oder andere Geräte darauf zugreifen können, die mit dem Google-Konto " +
                        "verknüpft sind.")
            }
        }
    }

    // ICAL_ICLOUD
    Accordion {
        expanded = accordionView == AccordionView.ICAL_ICLOUD
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.ICAL_ICLOUD else null) }

        AccordionSummary {
            id = "ical-icloud-header"
            ariaControls = "ical-icloud-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Abonnieren via iCloud Kalender"
            }
        }

        AccordionDetails {
            id = "ical-icloud-content"
            ariaLabelledBy = "ical-icloud-header"

            Typography {
                paragraph = true

                +"Unter "
                strong { +"MacOS" }
                +(" könnt ihr die Termine gemäß ")
                Link {
                    target = WindowTarget._blank
                    href = "https://support.apple.com/de-de/HT202361"
                    +"dieser Anleitung von Apple"
                }
                +" in eure iCloud importieren. Verwendet dabei die "
                Link {
                    sx = jso { cursor = Cursor.pointer }
                    onClick = { setAccordionView(AccordionView.SUBSCRIPTION) }
                    strong { +"iCal-Adressen" }
                }
                +" eurer Wahl."
            }

            Typography {
                paragraph = true

                +("Sobald die Termine in eurer iCloud vorhanden sind, solltet ihr auch über das Smartphone / Tablet " +
                        "oder andere Geräte darauf zugreifen können, die mit dem iCloud-Konto verknüpft sind.")
            }
        }
    }

    // ICAL_OTHER
    Accordion {
        expanded = accordionView == AccordionView.ICAL_OTHER
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.ICAL_OTHER else null) }

        AccordionSummary {
            id = "ical-other-header"
            ariaControls = "ical-other-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Abonnieren in anderen Kalender-Programmen"
            }
        }

        AccordionDetails {
            id = "ical-other-content"
            ariaLabelledBy = "ical-other-header"

            Typography {
                paragraph = true

                +"Nahezu jedes Kalender-Programm kann die "
                Link {
                    sx = jso { cursor = Cursor.pointer }
                    onClick = { setAccordionView(AccordionView.SUBSCRIPTION) }
                    strong { +"iCal-Adressen" }
                }
                +(" eurer Wahl abonnieren (importieren und automatisch aktualisieren). " +
                        "Beispiele zur Einrichtung verschiedener bekannter Programme:")
            }

            Typography {
                sx = jso { marginBottom = 1.5.rem }
                component = ReactHTML.ul

                li {
                    Link {
                        target = WindowTarget._blank
                        href =
                            "https://support.microsoft.com/de-de/office/kalender-nach-outlook-importieren-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
                        +"Anleitung für Outlook"
                    }
                    +" (Abschnitt "
                    q { +"Hinzufügen von Internetkalendern" }
                    +")"
                }
                li {
                    Link {
                        target = WindowTarget._blank
                        href =
                            "https://support.microsoft.com/de-de/office/importieren-oder-abonnieren-eines-kalenders-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
                        +"Anleitung für Outlook.com"
                    }
                    +" (Abschnitt "
                    q { +"Abonnieren eines Kalenders" }
                    +")"
                }
                li {
                    Link {
                        target = WindowTarget._blank
                        href = "https://support.mozilla.org/de/kb/neue-kalender-erstellen"
                        +"Anleitung für Thunderbird"
                    }
                    +" (Abschnitt "
                    q { +"Kalender erstellen" }
                    +" ➔ "
                    q { +"iCalendar (ICS)" }
                    +")"
                }
            }

            Typography {
                paragraph = true

                +("In aller Regel erfahrt ihr vom Hersteller eurer Kalender-Software, " +
                        "wie ein iCal-Kalender abonniert werden kann.")
            }
        }
    }

    // HELP
    Accordion {
        expanded = accordionView == AccordionView.HELP
        onChange = { _, expanded -> setAccordionView(if (expanded) AccordionView.HELP else null) }

        AccordionSummary {
            id = "help-header"
            ariaControls = "help-content"
            expandIcon = ExpandMore.create()

            Typography {
                component = ReactHTML.h2
                variant = TypographyVariant.h6

                +"Fragen oder Probleme?"
            }
        }

        AccordionDetails {
            id = "help-content"
            ariaLabelledBy = "help-header"

            Typography {
                paragraph = true

                +"Bei Fragen oder Unklarheiten könnt ihr mich via "
                Link {
                    target = WindowTarget._blank
                    href = AUTHOR_TWITTER_URL
                    +"Twitter"
                }
                +", "
                Link {
                    target = WindowTarget._blank
                    href = AUTHOR_INSTAGRAM_URL
                    +"Instagram"
                }
                +" oder "
                Link {
                    target = WindowTarget._blank
                    href = AUTHOR_FACEBOOK_URL
                    +"Facebook"
                }
                +" kontaktieren."
                //+" Oder schaut mal in unserer "
                //Link {
                //    target = WindowTarget._blank
                //    href = DISCORD_URL
                //    +"Discord-Community"
                //}
                //+" vorbei. Dort kann euch ebenfalls weitergeholfen werden."
            }
        }
    }

    Box {
        sx = jso { marginTop = important(1.5.rem) }
    }
}
