package de.kampfkalender.web.utils

import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.Promotion
import de.kampfkalender.common.Sport
import kotlinx.browser.localStorage
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

val FILTER_ALL = Filter()
val FILTER_FREE = Filter(free = true)

@Serializable
data class Filter(
    val promotions: List<Promotion> = listOf(),
    val broadcasters: List<Broadcaster> = listOf(),
    val sports: List<Sport> = listOf(),
    val free: Boolean = false,
) : Comparable<Filter> {
    val title: String
        get() = getTitle()

    companion object {
        fun readFromStorage(): List<Filter> {
            return try {
                Json.decodeFromString<List<Filter>>(localStorage.getItem("filters") ?: "[]")
                    .filter { !it.isEmpty() }.distinctBy { it.title }.sorted()
            } catch (e: Throwable) {
                console.warn("Can't read filters from local storage!", e)
                listOf()
            }
        }

        fun writeToStorage(filters: List<Filter>) {
            try {
                localStorage.setItem(
                    "filters", Json.encodeToString(
                        filters.filter { !it.isEmpty() }.distinctBy { it.title }.sorted()
                    )
                )
            } catch (e: Throwable) {
                console.warn("Can't write filters into local storage!", e)
            }
        }
    }

    override fun compareTo(other: Filter): Int {
        return this.title.compareTo(other.title, true)
    }

    fun isCustom(): Boolean {
        return !isEmpty()
    }

    fun isEmpty(): Boolean {
        return promotions.isEmpty() && broadcasters.isEmpty() && sports.isEmpty()
    }

    private fun getTitle(): String {
        if (!isCustom()) {
            return if (free) {
                "kostenlose Events auf allen Sendern"
            } else {
                "alle Events auf allen Sendern"
            }
        }

        var output = ""
        if (sports.isNotEmpty()) {
            output += sports.joinToString(" / ") { it.title }
        }

        if (promotions.isNotEmpty()) {
            if (output.isNotEmpty()) {
                output += " von "
            }
            output += promotions.joinToString(" / ") { it.acronym }
        }

        if (broadcasters.isNotEmpty()) {
            if (output.isNotEmpty()) {
                output += " bei "
            }
            output += broadcasters.joinToString(" / ") { it.title }
        }

        if (free) {
            output += " (kostenlos)"
        }

        return output
    }
}