package de.kampfkalender.web.utils

import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.Promotion
import de.kampfkalender.common.Sport
import kotlinx.browser.window
import kotlin.math.pow
import kotlin.math.roundToInt


/**
 * Base URL, the application is running on.
 */
val BASE_URL = "${window.location.protocol}//${window.location.host}"

const val TWITTER_URL = "https://twitter.com/KampfKalender"
//const val DISCORD_URL = "https://discord.com/invite/tz8ukHHhH4"
const val GITHUB_URL = "https://github.com/DerDampfHans/kampfkalender.de"
const val AUTHOR_TWITTER_URL = "https://twitter.com/DerDampfHans"
const val AUTHOR_FACEBOOK_URL = "https://www.facebook.com/hans.dampf.13089/"
const val AUTHOR_INSTAGRAM_URL = "https://www.instagram.com/derdampfhans/"

/**
 * Currently used timezone.
 */
val TIMEZONE = getCurrentTimeZone()

/**
 * Sorted list of active broadcasters.
 */
val BROADCASTERS = Broadcaster.values()
    .filter { it.active }
    .sortedBy { it.title.lowercase() }

/**
 * Sorted list of active promotions.
 */
val PROMOTIONS = Promotion.values()
    .filter { it != Promotion._OTHER && it.active }
    .sortedWith(compareBy<Promotion> { it.acronym.lowercase() }.thenBy { it.title.lowercase() })
    .plus(Promotion._OTHER)

/**
 * Sorted list of sports.
 */
val SPORTS = Sport.values()
    .sortedBy { it.title.lowercase() }


/**
 * Converts an integer to a string and adds a zero prefix, if its value is lower than 10.
 */
internal fun Int.zeroPrefix(): String {
    return if (this < 10) {
        "0${this}"
    } else {
        this.toString()
    }
}

fun Double.roundTo(numFractionDigits: Int): Double {
    val factor = 10.0.pow(numFractionDigits.toDouble())
    return (this * factor).roundToInt() / factor
}
