package de.kampfkalender.web

import de.kampfkalender.web.components.SidebarLink
import de.kampfkalender.web.utils.GITHUB_URL
import de.kampfkalender.web.utils.TIMEZONE
import de.kampfkalender.web.utils.TWITTER_URL
import de.kampfkalender.web.utils.formatAsDateTime
import de.kampfkalender.web.utils.toDate
import js.core.jso
import kotlinx.browser.window
import mui.icons.material.Close
import mui.icons.material.GitHub
import mui.icons.material.InstallMobile
import mui.icons.material.LiveTv
import mui.icons.material.Schedule
import mui.icons.material.Subscriptions
import mui.icons.material.Twitter
import mui.material.Box
import mui.material.Divider
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.material.DrawerVariant
import mui.material.IconButton
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.SvgIconSize
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML
import react.useContext
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.number
import web.cssom.px
import web.cssom.rem
import web.cssom.vh
import web.cssom.vw
import mui.material.List as MaterialList

val Sidebar = FC<Props> {
    val app = useContext(ApplicationContext)!!
    val lastUpdate = app.summary?.lastUpdate?.toDate()

    Drawer {
        variant = DrawerVariant.temporary
        open = app.sidebarOpened
        // anchor = DrawerAnchor.left
        anchor = DrawerAnchor.right
        onBackdropClick = { app.setSidebarOpened(false) }

        Stack {
            sx = jso {
                height = 100.vh
                width = 100.vw
                maxWidth = 250.px
            }

            //spacing = responsive(1.em)
            direction = responsive(StackDirection.column)
            divider = Divider.create()

            Box {
                sx = jso {
                    flexGrow = number(0.0)
                    flexShrink = number(0.0)
                    textAlign = TextAlign.right
                }

                IconButton {
                    onClick = { app.setSidebarOpened(false) }
                    size = Size.large
                    Close()
                }
            }

            MaterialList {
                sx = jso {
                    flexGrow = number(1.0)
                    paddingTop = 0.px
                    paddingBottom = 0.px
                }

                SidebarLink {
                    title = "Kalender"
                    target = "/"
                    icon = Schedule
                }

                SidebarLink {
                    title = "Wer zeigt was?"
                    target = "/broadcaster"
                    icon = LiveTv
                }

                SidebarLink {
                    title = "Bleib' up to date!"
                    target = "/subscribe"
                    icon = Subscriptions
                }
            }

            if (app.canInstall()) {
                SidebarLink {
                    title = "App installieren"
                    icon = InstallMobile
                    onClick = {
                        app.doInstall()
                    }
                }
            }

            Box {
                sx = jso {
                    paddingTop = 0.75.rem
                    paddingBottom = 0.75.rem
                    paddingLeft = 0.5.rem
                    paddingRight = 0.5.rem
                    textAlign = TextAlign.center
                }

                Typography {
                    component = ReactHTML.div
                    variant = TypographyVariant.caption
                    +"Deine Zeitzone:"
                }

                Typography {
                    sx = jso {
                        lineHeight = number(1.0)
                    }
                    component = ReactHTML.div
                    variant = TypographyVariant.overline
                    +TIMEZONE
                }

                if (lastUpdate != null) {
                    Typography {
                        sx = jso {
                            marginTop = 0.75.rem
                        }
                        component = ReactHTML.div
                        variant = TypographyVariant.caption
                        +"Letztes Update:"
                    }

                    Typography {
                        sx = jso {
                            lineHeight = number(1.0)
                        }
                        component = ReactHTML.div
                        variant = TypographyVariant.overline
                        +lastUpdate.formatAsDateTime(false)
                    }
                }
            }

            Stack {
                sx = jso {
                    padding = 0.5.rem
                    justifyContent = JustifyContent.spaceAround
                }

                spacing = responsive(1.rem)
                direction = responsive(StackDirection.row)

                Tooltip {
                    title = ReactNode("Kampfkalender bei Twitter")
                    IconButton {
                        onClick = { window.open(TWITTER_URL, "_blank") }
                        Twitter {
                            fontSize = SvgIconSize.large
                        }
                    }
                }

                //Tooltip {
                //    title = ReactNode("Kampfkalender bei Discord")
                //    IconButton {
                //        onClick = { window.open(DISCORD_URL, "_blank") }
                //
                //        // Discord Icon by Font Awesome
                //        // https://fontawesome.com/v6.0/icons/discord?s=brands
                //        //
                //        // <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" class="svg-inline--fa fa-discord" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"></path></svg>
                //
                //        SvgIcon {
                //            fontSize = SvgIconSize.large
                //            viewBox = "0 0 640 512"
                //            ReactSVG.path {
                //                d =
                //                    "M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"
                //            }
                //        }
                //    }
                //}

                Tooltip {
                    title = ReactNode("Kampfkalender bei GitHub")
                    IconButton {
                        onClick = { window.open(GITHUB_URL, "_blank") }
                        GitHub {
                            fontSize = SvgIconSize.large
                        }
                    }
                }
            }
        }
    }
}
