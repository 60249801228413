package de.kampfkalender.web.pages

import de.kampfkalender.common.Broadcaster
import de.kampfkalender.common.Promotion
import de.kampfkalender.web.ApplicationContext
import de.kampfkalender.web.utils.BROADCASTERS
import de.kampfkalender.web.utils.PROMOTIONS
import de.kampfkalender.web.utils.SMALL_SIZE_MEDIA_QUERY
import de.kampfkalender.web.utils.getLogoWide
import js.core.jso
import kotlinx.browser.window
import mui.icons.material.ExpandMore
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Box
import mui.material.Chip
import mui.material.ChipColor
import mui.material.Divider
import mui.material.Link
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.useMediaQuery
import mui.system.responsive
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.aria.ariaControls
import react.dom.aria.ariaLabelledBy
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.strong
import react.useContext
import react.useEffectOnce
import react.useState
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.None
import web.cssom.VerticalAlign
import web.cssom.important
import web.cssom.pct
import web.cssom.px
import web.cssom.rem
import web.cssom.vh
import web.cssom.vw
import web.window.WindowTarget

val BroadcasterPage = FC<Props> {
    val app = useContext(ApplicationContext)!!
    val isSmall = useMediaQuery(SMALL_SIZE_MEDIA_QUERY)
    val (selectedBroadcaster, setSelectedBroadcaster) = useState<Broadcaster?>(null)

    useEffectOnce {
        window.scrollTo(0.0, 0.0)
    }

    Typography {
        sx = jso {
            paddingTop = 1.5.rem
        }

        component = ReactHTML.h1
        variant = TypographyVariant.h1
        +"Welcher Sender überträgt was?"
    }

    Divider {
        sx = jso {
            marginTop = 0.5.rem
            marginBottom = 1.0.rem
        }
    }

    Typography {
        paragraph = true
        +"Dies ist eine Übersicht, welche Sender bestimmte Kampfsport-Veranstaltungen zumindest gelegentlich übertragen. "
        +"Die Rechte für Einzelveranstaltungen (z.B. große Events im Boxen) werden meist einzeln durch die Sender "
        +"eingekauft und erscheinen daher "
        strong { +"nicht" }
        +" in dieser Auflistung."
    }

    Typography {
        paragraph = true
        +"Die Liste wird gemeinsam mit dem Kalender nach bestem Wissen und Gewissen regelmäßig aktualisiert. "
        +"Trotzdem kann ich euch leider nicht garantieren, dass immer alle Angaben 100%ig fehlerfrei sind."
    }

    BROADCASTERS.forEach { broadcaster: Broadcaster ->
        val promotions = PROMOTIONS.filter {
            !it.podcast && (
                    it.mainCardBroadcaster.contains(broadcaster) ||
                            it.prelimsBroadcaster.contains(broadcaster) ||
                            it.earlyPrelimsBroadcaster.contains(broadcaster)
                    )
        }

        if (promotions.isEmpty()) {
            return@forEach
        }

        Accordion {
            expanded = selectedBroadcaster == broadcaster
            onChange = { _, expanded -> setSelectedBroadcaster(if (expanded) broadcaster else null) }

            AccordionSummary {
                sx = jso {
                    flexDirection = FlexDirection.rowReverse
                }

                id = "broadcaster-${broadcaster.name}-header"
                ariaControls = "broadcaster-${broadcaster.name}-content"
                expandIcon = ExpandMore.create()

                Tooltip {
                    title = ReactNode("Was bei ${broadcaster.title} regelmäßig übertragen wird.")
                    //placement = TooltipPlacement.bottomStart
                    followCursor = true

                    Stack {
                        sx = jso {
                            width = 100.pct
                            marginLeft = 0.5.rem
                            justifyContent = JustifyContent.spaceBetween
                        }

                        direction = responsive(StackDirection.row)
                        spacing = responsive(0.5.rem)

                        val logo = broadcaster.getLogoWide(app.darkMode)
                        if (logo == null) {
                            Typography {
                                component = ReactHTML.h2
                                variant = TypographyVariant.h6

                                +broadcaster.title
                            }
                        } else {
                            ReactHTML.img {
                                alt = broadcaster.title
                                src = "/logos/$logo"
                                style = jso {
                                    maxHeight = 30.px
                                    maxWidth = 60.vw
                                    height = 100.vh
                                }
                            }
                        }

                        Chip {
                            label = ReactNode(promotions.size.toString())
                        }
                    }
                }
            }

            AccordionDetails {
                sx = jso {
                    padding = 0.px
                }

                id = "broadcaster-${broadcaster.name}-content"
                ariaLabelledBy = "broadcaster-${broadcaster.name}-header"

                TableContainer {
                    Table {
                        TableHead {
                            TableRow {
                                TableCell {
                                    sx = jso {
                                        if (isSmall) {
                                            display = None.none
                                        }
                                    }

                                    +"Abkürzung"
                                }
                                TableCell {
                                    +"Veranstalter"
                                }
                                TableCell {
                                    +"Card"
                                }
                            }
                        }
                        TableBody {
                            promotions.forEach { promotion: Promotion ->
                                TableRow {
                                    sx = jso {
                                        verticalAlign = VerticalAlign.top
                                    }

                                    key = "${broadcaster.name}-${promotion.name}-${isSmall}"
                                    TableCell {
                                        sx = jso {
                                            if (isSmall) {
                                                display = None.none
                                            }
                                        }

                                        +promotion.acronym
                                    }
                                    TableCell {
                                        if (promotion.tapologyUrl != null) {
                                            Link {
                                                href = promotion.tapologyUrl
                                                target = WindowTarget._blank

                                                +promotion.title

                                                if (isSmall && !promotion.title.contains(promotion.acronym, true)) {
                                                    +" (${promotion.acronym})"
                                                }
                                            }
                                        } else {
                                            +promotion.title

                                            if (isSmall && !promotion.title.contains(promotion.acronym, true)) {
                                                +" (${promotion.acronym})"
                                            }
                                        }
                                    }
                                    TableCell {
                                        if (promotion.mainCardBroadcaster.contains(broadcaster)) {
                                            Box {
                                                +"Main Card "

                                                if (promotion.mainCardFree) {
                                                    Chip {
                                                        label = ReactNode("kostenlos")
                                                        color = ChipColor.success
                                                        size = Size.small
                                                    }
                                                }
                                            }
                                        }
                                        if (promotion.prelimsBroadcaster.contains(broadcaster)) {
                                            Box {
                                                +"Prelims "

                                                if (promotion.prelimsFree) {
                                                    Chip {
                                                        label = ReactNode("kostenlos")
                                                        color = ChipColor.success
                                                        size = Size.small
                                                    }
                                                }
                                            }
                                        }
                                        if (promotion.earlyPrelimsBroadcaster.contains(broadcaster)) {
                                            Box {
                                                +"Early Prelims "

                                                if (promotion.earlyPrelimsFree) {
                                                    Chip {
                                                        label = ReactNode("kostenlos")
                                                        color = ChipColor.success
                                                        size = Size.small
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    Box {
        sx = jso { marginTop = important(1.5.rem) }
    }
}
